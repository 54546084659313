import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import Dropdown from '../../../../inputs/Dropdown';
import TabLink from './TabLink';
import CopyIcon from '../../../../assets/icons/copyIcon'
import {
	returnAgeSeconds,
	getSecondsFromTimeRange,
	convertSecondsToTime,
	returnAgeFromTimestamp,
	numberWithCommas,
	returnHoursFromTimestamp,
	returnAgeFromDepartmentHistories,
	returnAgeFromDepartmentHistories_24_7,
	getSecondsFromTimeRange_24_6,
	getSecondsFromTimeRange_24_5,
	returnTabAge,
	returnTabAge_24_5,
	returnTabAge_24_6,
	returnTabAge_24_7,
	noteStamp,
} from '../../../../utils/dateFunctions';
import {
	lookupPermission,
	lookupProfilePermission,
	lookupIsAdmin,
	activeVehicle,
	vsearch,
} from '../../../../actions/lookups';
import {
	setVehicleTab,
} from '../../../../actions/apiSetInfo';
import {
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	updateVehicleData,
} from '../../../../actions/AllUpdates';

import './VehicleDisplayInfoHeader.scss'



class VehicleDisplayInfoHeader extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { copied: '' };
		this.onTabClick = this.onTabClick.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
		this.unDeleteVehicle = this.unDeleteVehicle.bind(this);
	}

	tabHistory(){
		const end = new Date();
		if (this.props.vehicle.completed_departments) {
			const days = 0;
			let department_time = 0;
			const department_id = this.props.vehicle.department_id;

			const first_time_tab = this.dealerSettingValue('first_time_tab');
			const second_time_tab = this.dealerSettingValue('second_time_tab');
			const third_time_tab = this.dealerSettingValue('third_time_tab');

			let first_time = '';
			let second_time = '';
			let third_time = '';

			first_time = first_time_tab === 'none' ? '' : this.returnTimeDisplay(first_time_tab);
			second_time = second_time_tab === 'none' ? '' : this.returnTimeDisplay(second_time_tab);
			third_time = third_time_tab === 'none' ? '' : this.returnTimeDisplay(third_time_tab);

			department_time = getSecondsFromTimeRange(this.props.vehicle.department_time, end) / 3600;

			return(<div className="vehicle-display-info-header-tab-history" title={`${first_time_tab} / ${second_time_tab} / ${third_time_tab}`}>
				{first_time}
				{' '}
	|
				{' '}
				{second_time}
				{' '}
	|
				{' '}
				{third_time}
				{' '}
			</div>);
		} else {
			var tabStyle = successStyle;
			return(<div className="vehicle-display-info-header-tab-history">0</div>);
		}

	}

	onTabClick(e) {
		if (this.props.vehicle_tab === 'vehicle-body' && this.props.edit_vehicle === this.props.vehicle.id) {
			this.props.setVehicleTab('vehicle-body', 0);
		} else {
			this.props.setVehicleTab('vehicle-body', this.props.vehicle.id);
		}
	}

	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	unDeleteVehicle() {
		const self = this;
		const data = {
			id: this.props.vehicle.id,
			sold: false,
			deleted: false,
		};

		Promise.all([
			this.props.updateVehicleData(this.props.vehicle.id, data),
		]).then(() => {
			if (self.props.vehicle_filter.type === 'searched') {
				self.props.vsearch(vehicle_filter.value);
			} else {
				self.props.getCurrentDepartment(self.props.vehicle.department_id);
			}
		});
	}

	copyToClipboard(e) {
		this.setState({ copied: 'Copied!' });
		setTimeout(() => { this.setState({ copied: '' }); }, 3000);
		copy(this.props.vehicle.vin);
	}

	returnTimeDisplay(time_tab) {
		const vehicle = this.props.vehicle || [];
		const { completed_departments } = vehicle;
		const work_flows = this.props.work_flows;
		let vehicle_workflow = '';
		let plus_seconds = 0;
		let work_flow_properties = [];
		const end = new Date();
		let seconds = 0;

		switch (vehicle.stocktype.toUpperCase()) {
		case 'N':
			vehicle_workflow = this.dealerSettingValue('recon_new_work_flow');
			break;
		case 'U':
			vehicle_workflow = this.dealerSettingValue('recon_used_work_flow');
			break;
		case 'W':
			vehicle_workflow = this.dealerSettingValue('recon_wholesale_work_flow');
			break;
		default:
			vehicle_workflow = '';
		}

		if (vehicle_workflow !== '') {
			const work_flow = _.find(work_flows, { name: vehicle_workflow }) || '';

			if (work_flow.work_flow_properties.length > 0) {
				work_flow_properties = work_flow.work_flow_properties;
			}
		}

		switch (time_tab) {
		case 'age-24_7':
			seconds = getSecondsFromTimeRange(vehicle.created_at, end);
			break;
		case 'age-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.created_at, end);
			break;
		case 'age-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.created_at, end);
			break;
		case 'age-recononly-24_7':
			plus_seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			seconds = returnTabAge_24_7(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_6':
			plus_seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			seconds = returnTabAge_24_6(completed_departments, work_flow_properties);
			break;
		case 'age-recononly-24_5':
			plus_seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			seconds = returnTabAge_24_5(completed_departments, work_flow_properties);
			break;
		case 'department-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		case 'department-instance-24_7':
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		case 'department-instance-24_6':
			seconds = getSecondsFromTimeRange_24_6(vehicle.department_time, end);
			break;
		case 'department-instance-24_5':
			seconds = getSecondsFromTimeRange_24_5(vehicle.department_time, end);
			break;
		default:
			seconds = getSecondsFromTimeRange(vehicle.department_time, end);
			break;
		}

		let days = 0;
		days = seconds + plus_seconds;

		if (days > (24 * 60 * 60)) {
			if (days > (24 * 60 * 60 * 365)) {
				var time = 0;
			} else {
				var time = (days / (24 * 60 * 60));
				time = `${parseInt(time)} days`;
			}
		} else {
			var time = (days / (60 * 60));
			time = `${parseInt(time)} hrs`;
		}
		return time;
	}
	dealerSettingValue(name) {
		const self = this;
		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	selectDepartments() {
		const results = [];
		const isAdmin = this.props.lookupIsAdmin();
		const remove_department_types = ['recently sold', 'data step', 'all reconditioning', 'parent step'];
		if (this.props.softname.includes('classadsfasdfadfsadic')) {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					const showTab = isAdmin || this.props.lookupProfilePermission(`allowed_move_${option.name}`);
					if (showTab) {
						if (typeof option.name !== 'undefined') {
							var obj = { id: parseInt(option.id), label: option.name, selected };
						} else {
							var obj = { id: parseInt(option.id), label: option.name, selected };
						}
						results.push(obj);
					} else if (selected === true) {
						results.push(obj);
					}
				}
			});
		} else if (this.props.softname.includes('classic')) {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		} else if (this.props.installation.database === 'wp') {
			this.props.dealership_departments.forEach((option) => {
				var stocktype = this.props.vehicle.stocktype;
				var work_flow = [];
				var find_department = [];

				if (stocktype === 'N') {
					work_flow = _.find(this.props.work_flows, { name: 'new' }) || [];
					find_department = _.find(work_flow.work_flow_properties, { department_id: option.id }) || [];
				} else {
					work_flow = _.find(this.props.work_flows, { name: 'used' }) || [];
					find_department = _.find(work_flow.work_flow_properties, { department_id: option.id }) || [];
				}

				if (!remove_department_types.includes(option.department_type) && find_department.length !== 0) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		} else {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.department_id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		}

		return results;
	}

	render() {
		const { vehicle } = this.props;
		const self = this;
		const isAdmin = this.props.lookupIsAdmin();

		let certified = '';
		let goldstar = '';
		let greenstar = '';
		let purplestar = '';
		let straightstar = '';
		let uvistar = '';
		const salescost = this.vehicleProperty('salescost');

		if (this.vehicleProperty('certified') == 'true') {
			certified = 'Certified |';
		} else if (this.vehicleProperty('not certified') == 'true') {
			certified = 'Not Certified |';
		} else if (this.vehicleProperty('wholesale') == 'true') {
			certified = 'Wholesale |';
		}

		if (this.vehicleProperty('goldstar') == 'true') {
			goldstar = <div className="d-inline"><i className="fa fa-star color-gold" /></div>;
		} else {
			goldstar = '';
		}

		if (this.vehicleProperty('greenstar') == 'true') {
			greenstar = <div className="d-inline"><i className="fa fa-star color-green" /></div>;
		} else {
			greenstar = '';
		}

		if (this.vehicleProperty('purplestar') == 'true') {
			purplestar = <div className="d-inline"><i className="fa fa-star color-purple" /></div>;
		} else {
			purplestar = '';
		}

		if (this.vehicleProperty('straightstar') == 'true') {
			straightstar = <div className="d-inline"><i className="fa fa-star color-pink" /></div>;
		} else {
			straightstar = '';
		}

		if (this.vehicleProperty('uvistar') == 'true') {
			uvistar = <div className="d-inline"><i className="fa fa-star color-skyblue" /></div>;
		} else {
			uvistar = '';
		}

		var atlanta = self.props.installation.subdomain.includes('automaxatlanta');

		var vehicle_cost = true;
		if (self.props.installation && self.props.softname.toLowerCase().includes('stanley')) {
			vehicle_cost = false;
			if (this.props.lookupPermission('vehicle_cost') || isAdmin) {
				vehicle_cost = true;
			}
		}

		if (self.props.installation && self.props.installation.database == 'astorg') {
			vehicle_cost = false;
			if (this.props.lookupPermission('vehicle_cost') || isAdmin) {
				vehicle_cost = true;
			}
		}

		if (parseInt(salescost) > 0 && !atlanta) {
			certified = `${certified} ${vehicle_cost ? ` $${numberWithCommas(parseInt(salescost))}` : ''}`;
		}

		const color_vehicle_tab = self.dealerSettingValue('color_vehicle_tab');
		let listStyle = {
			background: color_vehicle_tab || 'white',
			backgroundColor: color_vehicle_tab || 'white',
			color: 'rgb(60,60,60)',
		};

		const vehicle_tab_override = self.dealerSettingValue('vehicle_tab_override');
		const vehicle_tab_override_meta = self.dealerSettingValue('vehicle_tab_override_meta');
		const override_value = vehicle[vehicle_tab_override_meta] || this.vehicleProperty(vehicle_tab_override_meta);
		const background_color_override = self.dealerSettingValue(`background_color_override_${override_value}`);
		const text_color_override_ = self.dealerSettingValue(`text_color_override_${override_value}`);

		if (vehicle_tab_override === 'true' && override_value !== '') {
			listStyle = {
				background: background_color_override || color_vehicle_tab || 'white',
				backgroundColor: background_color_override || color_vehicle_tab || 'white',
				color: text_color_override_ || self.dealerSettingValue('color_vehicle_tab_text') || 'rgb(60,60,60)',
			};
		}

		var setdisabled = true;

		if (this.props.lookupPermission('vehicle_move_step') || this.props.lookupIsAdmin()) {
			setdisabled = false;
		}

		var loc = <div
			className="col-md-12 dept-pad"
			key={`${vehicle.id}_${vehicle.department_id}`}
		>
			<Dropdown
				{...this.props}
				titleHelper="Departments"
				title={'Select Department'}
				list={this.selectDepartments()}
				update_type={'select_department'}
				user_id={this.props.user_id}
				department_id={vehicle.department_id}
				value={vehicle.department_id}
				vehicle_id={vehicle.id}
				vehicle={vehicle}
				disabled={setdisabled}
			/>
		</div>;

		var customer_name = <div className="d-inline">{this.vehicleProperty('customer name') !== '' ? ` - ${this.vehicleProperty('customer name')}` : ''}</div>;

		var lot_status = '';
		if (this.vehicleProperty('lot status') !== '') {
			lot_status = <div className="d-inline"> <br /> - {this.vehicleProperty('lot status')}</div>;
		}

		if (vehicle.subdomain !== self.props.current_dealership && self.props.current_dealership !== vehicle.subdomain.substring(0, vehicle.subdomain.length - 1) && self.props.installation.show_all_subdomains !== true) {
			var loc = <button className="btn btn-sm btn-warning">{self.department(vehicle.temp_department).name || `Vehicle at ${vehicle.subdomain}`}</button>;
		}

		if (vehicle.sold === true || vehicle.deleted === true) {
			var loc = <button className="btn btn-sm btn-danger" onClick={this.unDeleteVehicle}>Restore Vehicle to Active</button>;
		}

		return (
			<div className="vehicle-display-info-header">
				<div className="ReactVehicleHeadTab vehicle-display-info-header-status" onClick={(e)=>{
					this.onTabClick(e)
				}} style={listStyle}>
					<div className="d-flex">
						<div className="text-dark text-capitalize">
							{loc}
						</div>
						<div onClick={(e)=>{
							this.props.setVehicleTab('tab_history', this.props.vehicle.id);
							e.stopPropagation()
						}}>
							{this.props.all_tabs.map((tab) => {
								const showTab = isAdmin || this.props.lookupProfilePermission(tab.name);
								return (tab.active && showTab && tab.name==='tab_history') ? <TabLink onTabClick={self.onTabClick} vehicle={vehicle} vehicle_id={vehicle.id} name={tab.name} icon={tab.icon} colortext="white" tab={tab} key={`tab_${tab.id}_${vehicle.id}`} /> : null;
							})}
						</div>
						<div className="vehicleInfo text-capitalize">
							<mark className="float-end">
								<span name="dealership">
									{vehicle.dealership.replace('_', ' ')}
									{' '}
									{customer_name} {lot_status}
								</span>
								<span key={`star_${vehicle.id}`}>
									{goldstar}
									{greenstar}
									{purplestar}
									{straightstar}
									{uvistar}
								</span>
								<span name="certified">{certified}</span>
							</mark><br />
						</div>
					</div>
				</div>

				{this.props.all_tabs.map((tab) => {
					const showTab = isAdmin || this.props.lookupProfilePermission(tab.name);
					return (tab.active && showTab && tab.name==='tab_checkboxes') ? <TabLink onTabClick={self.onTabClick} vehicle={vehicle} vehicle_id={vehicle.id} name={tab.name} icon={tab.icon} colortext="white" tab={tab} key={`tab_${tab.id}_${vehicle.id}`} /> : null;
				})}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		current_department: state.settings_departments.department,
		departments: state.settings_departments.departments,
		dealership_departments: state.settings_departments.dealership_departments,
		dealer_settings: state.settings_site.dealer_settings,
		all_tabs: state.settings_site.all_tabs,
		edit_vehicle: state.settings_nav.edit_vehicle,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		current_user_profile: state.settings_users.current_user_profile,
		softname: state.settings_dealerships.installation.softname || '',
		vehicle_filter: state.settings_nav.vehicle_filter,
		work_flows: state.settings_departments.work_flows,
	};
}
export default connect(mapStateToProps, {
	lookupIsAdmin,
	lookupPermission,
	lookupProfilePermission,
	setVehicleTab,
	updateVehicleData,
	activeVehicle,
	getCurrentDepartment,
	vsearch,
})(VehicleDisplayInfoHeader);
