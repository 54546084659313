import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import Select from 'react-select';
import { SketchPicker, SwatchesPicker } from 'react-color';
import DropdownMultiple from './DropdownMultiple';
import Dropdown from './Dropdown';
import { updateDepartmentSetting } from '../actions/apiDepartmentUpdates';

class EditDepartmentSettings extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: this.props.value || '', checked: this.props.checked || '', background_color: this.props.background_color, text_color: this.props.text_color,
		};
		this.onChange = this.onChange.bind(this);
		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangeSelect = this.onChangeSelect.bind(this);
		this.handleChangeComplete = this.handleChangeComplete.bind(this);
		this.onRelatedInstallationsChangeSelect = this.onRelatedInstallationsChangeSelect.bind(this);
		this.selectRelatedInstallationsSingle = this.selectRelatedInstallationsSingle.bind(this);
	}

	onChange(event) {

	}

	handleChangeComplete(e) {
		this.setState({ value: e.hex });

		const self = this;
		const errorType = 'editColorPicker';
		const data = {
			id: self.props.parentid,
			[this.props.name]: e.hex,
		};

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeInput(e) {
		this.setState({ value: e.target.value });
	}

	onBlur(e) {
		if (e.target.value !== this.props.value) {
			const self = this;
			const errorType = 'editUserProfileError';
			const data = {
				id: self.props.parentid,
				[e.target.name]: e.target.value,
			};

			self.setState({ value: e.target.value });

			self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
		}
	}

	onChangeSelect(e) {
		const self = this;
		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[self.props.name]: e.value,
		};

		self.setState({ value: e.value });
		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onRelatedInstallationsChangeSelect(e) {
		const self = this;

		const dealerArray = e.map(dealer => dealer.value);

		const errorType = 'editVehicleColumnError';

		const data = {
			id: self.props.parentid,
			[self.props.name]: dealerArray.join(),
		};

		self.setState({ value: dealerArray });

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	onChangeChecked(e) {
		const self = this;

		const errorType = 'editVehicleColumnError';
		const data = {
			id: self.props.parentid,
			[e.target.name]: e.target.checked,
		};

		self.setState({ checked: e.target.checked });

		self.props.updateDepartmentSetting(self.props.parentid, data, errorType);
	}

	selectOptions() {
		const departments = this.props.options.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return departments;
	}

	selectData() {
		const options = this.props.vehicle_columns.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.name };
			} else {
				var obj = { value: option, label: option };
			}
			return obj;
		});
		return options;
	}

	selectDepartments(excludeCurrent = false) {
		const departments = excludeCurrent
			? this.props.departments.filter(d => d.id !== this.props.edit_department.id)
			: this.props.departments;

		const returnDepartments = departments.map((option) => {
			let selected = false;
			if (option.id === parseInt(this.props.value)) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: parseInt(option.id), label: option.name, selected };
			} else {
				var obj = { id: parseInt(option.id), label: option.name, selected };
			}
			return obj;
		});
		returnDepartments.push({ id: 0, label: 'None Selected' });
		return returnDepartments;
	}

	selectWorkFlows() {
		const work_flows = this.props.value || '';

		const work_flows_values = work_flows.split(',');

		const options = this.props.work_flows.map((option) => {
			let selected = false;

			if (work_flows_values.includes(option.name)) {
				selected = true;
			}

			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.name, selected };
			} else {
				var obj = { id: option.id, label: option.name, selected };
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallations() {
		const dealerships = this.props.value || '';

		const dealer_values = dealerships.toString().split(',');

		const selected_dealerships = [];

		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;

			if (dealer_values.includes(option.subdomain)) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid, subdomain: option.subdomain,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid, subdomain: option.subdomain,
				};
			}
			return obj;
		});
		return options;
	}

	selectRelatedInstallationsSingle() {
		const options = this.props.related_installations.map((option) => {
			let selected = false;
			const dataid = 0;

			if (this.props.value == option.subdomain) {
				selected = true;
			}

			if (typeof option.softname !== 'undefined') {
				var obj = {
					id: option.id, label: option.softname, selected, dataid, subdomain: option.subdomain,
				};
			} else {
				var obj = {
					id: option.id, label: option.subdomain, selected, dataid, subdomain: option.subdomain,
				};
			}
			return obj;
		});
		return options;
	}

	selectComparison() {
		const comparisonArray = [{ name: 'Greater Than', label: 'Greater Than' },
			{ name: 'Greater or Equal', label: 'Greater or Equal' }, { name: 'Equals', label: 'Equals' }, { name: 'Not Equals', label: 'Not Equals' },
			{ name: 'Less Than', label: 'Less Than' }, { name: 'Less or Equal', label: 'Less or Equal' }, { name: 'Contains', label: 'Contains' }];

		const options = comparisonArray.map((option) => {
			if (typeof option.name !== 'undefined') {
				var obj = { value: option.name, label: option.label };
			} else {
				var obj = { value: option.name, label: option.label };
			}
			return obj;
		});
		return options;
	}

	selectDepartmentType() {
		const comparisonArray = [{ id: 1, name: 'transfer', label: 'transfer' },
			{ id: 2, name: 'return to origin', label: 'return to origin' },
			{ id: 3, name: 'department', label: 'department' },
			{ id: 4, name: 'data step', label: 'data step' },
			{ id: 6, name: 'inventory', label: 'inventory' },
			{ id: 7, name: 'recently sold', label: 'recently sold' },
			{ id: 8, name: 'all reconditioning', label: 'all reconditioning' },
			{ id: 9, name: 'all vehicles', label: 'all vehicles' },
			{ id: 10, name: 'parent step', label: 'parent step' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.label == this.props.value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = { id: option.id, label: option.label, selected };
			} else {
				var obj = { id: option.id, label: option.label, selected };
			}
			return obj;
		});
		return options;
	}

	returnDescription() {
		switch (this.props.name) {
		case 'active':
			return 'Step is Active';

		case 'goal':
			return 'Goal';

		case 'overdue_1':
			return '1st Notification';

		case 'overdue_2':
			return '2nd Notification';

		case 'overdue_3':
			return '3rd Notification';

		case 'children_departments':
			return 'Select children steps';

		case 'subgroup':
			return 'Parent step';

		case 'nonstep':
			return 'This stage is a representation of compared data. Example: "Jason", would be all vehicles that Jason appraised';

		case 'nonstep_meta':
			return 'This stage is a representation of compared data. Example: "Jason"';

		case 'nonstep_compare':
			return 'Comparison operator';

		case 'step':
			return 'Is this step considered part of the reconditioning process to be timed';

		case 'display_name':
			return 'Display Name';

		default:
			return '';
		}
	}

	render() {
		return (
			<div className="col-md-12">
							<div className={classnames('form-group', { 'has-danger': this.props.error })}>
								{(() => {
									switch (this.props.type) {
									case 'switch':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}_${this.props.parentid}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}_${this.props.parentid}`}>{this.props.label.replace(/_/g, ' ')}</label>
										</span>;

									case 'switch-goal':
										return <span className="switch">
											<input
												onChange={this.onChangeChecked}
												type={'checkbox'}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												checked={this.state.checked ? 'checked' : ''}
												className={classnames('text-center', { 'form-control-danger': this.props.error })}
												id={`switch_id_${this.props.name}_${this.props.parentid}`}
											/>
											<label className={'text-capitalize'} htmlFor={`switch_id_${this.props.name}_${this.props.parentid}`} />

										</span>;

									case 'select':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectOptions().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectOptions()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectData().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectData()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-data-raw':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectData().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectData()}
											className="text-capitalize"
											data-id={this.props.parentid}
											clearable={false}
											searchable
											key={`ds_${this.props.parentid}_${this.props.field}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'select-comparison':
										return <div className="form-group">
											<label className="control-label">{this.props.label}</label>
											<Select
												onChange={this.onChangeSelect}
												value={this.selectComparison().filter(({ value }) => value === this.state.value)}
												name={this.props.name}
												options={this.selectComparison()}
												className="text-capitalize"
												data-id={this.props.parentid}
												clearable={false}
												searchable={false}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												menuContainerStyle={{ zIndex: 5 }}
											/>
										</div>;

									case 'select-comparison-raw':
										return <Select
											onChange={this.onChangeSelect}
											value={this.selectComparison().filter(({ value }) => value === this.state.value)}
											name={this.props.name}
											options={this.selectComparison()}
											className="text-capitalize"
											data-id={this.props.parentid}
											clearable={false}
											searchable={false}
											key={`ds_${this.props.parentid}_${this.props.field}`}
											menuContainerStyle={{ zIndex: 5 }}
										/>;

									case 'select-department-type':

										return <div className="col-md-12">
											<label className="control-label text-white">Set Department Type</label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={this.props.value}
												list={this.selectDepartmentType()}
												update_type={'department_type'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-departments':

										return <div className="col-md-12">
											<label className="control-label text-white">Set Transfer Department</label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments()}
												update_type={'subdomain_department'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-subgroup':

										return <div className="col-md-12">
											<label className="control-label text-white">Set Parent Department</label>
											<Dropdown
												{...this.props}
												titleHelper="Departments"
												title={'Select Department'}
												list={this.selectDepartments(true)}
												update_type={'subgroup'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-workflow':

										return <div className="col-md-12">
											<label className="control-label text-white">Set Work Flows</label>
											<DropdownMultiple
												{...this.props}
												titleHelper="Work Flows"
												title="Select Work Flows"
												list={this.selectWorkFlows()}
												update_type={'work_flows'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												set_work_flows={this.props.value}
											/>
										</div>;

									case 'select-related-installations':

										return <div className="col-md-12">
											<label className="control-label text-white">Transfer Vehicle To Location</label>
											<Dropdown
												{...this.props}
												titleHelper="Location"
												title={'Select Location'}
												list={this.selectRelatedInstallationsSingle()}
												update_type={'subdomain'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												value={this.props.value}
											/>
										</div>;

									case 'select-related-installations-multiple':

										return <div className="col-md-12">
											<label className="control-label text-white">Locations</label>
											<DropdownMultiple
												{...this.props}
												titleHelper="Location"
												title="Select location"
												list={this.selectRelatedInstallations()}
												update_type={'department_dealerships'}
												user_id={this.props.user_id}
												department_id={this.props.parentid}
												dealers={this.props.value}
											/>
										</div>;

									case 'text':
										return <div className="form-group">
											<label className="control-label text-white">{this.props.label}</label>
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
										</div>;

									case 'order':
										return <div className="form-group">
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												placeholder={'Order'}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control w30', { 'form-control-danger': this.props.error })}
											/>
                 </div>;

									case 'text-raw':
										return <input
											onChange={this.onChangeInput}
											onBlur={this.onBlur}
											value={this.state.value}
											type={this.props.type}
											name={this.props.name}
											data-id={this.props.parentid}
											key={`ds_${this.props.parentid}_${this.props.field}`}
											className={classnames('form-control ', { 'form-control-danger': this.props.error })}
										/>;

									case 'text-goal':
										return <div className="form-group">
											<input
												onChange={this.onChangeInput}
												onBlur={this.onBlur}
												value={this.state.value}
												type={'number'}
												name={this.props.name}
												data-id={this.props.parentid}
												placeholder="Enter Hours"
												key={`ds_${this.props.parentid}_${this.props.field}`}
												className={classnames('form-control ', { 'form-control-danger': this.props.error })}
											/>
                 </div>;

									case 'colorpicker':
										return <div className="form-group pad-right-1 space-right-1">
											<label className="control-label text-white">{this.props.label}</label>
											<SwatchesPicker
												color={this.state.value}
												onChangeComplete={this.handleChangeComplete}
												type={this.props.type}
												name={this.props.name}
												data-id={this.props.parentid}
												key={`ds_${this.props.parentid}_${this.props.field}`}
												background={'transparent'}
											/>
										</div>;

									default:
										return <input
											onChange={this.onChange}
											onBlur={this.onBlur}
											value={this.state.value}
											type={this.props.type}
											name={this.props.field}
											className={classnames('form-control', { 'form-control-danger': this.props.error })}
										/>;
									}
								})()}

							</div>
						<div className="col-md-12">
							<small>
								<i className={'fa fa-info-circle text-info'} />
								{' '}
								{this.returnDescription()}
							</small>
						</div>
			</div>
		);
	}
}

const mappedActions = {
	updateDepartmentSetting,
};

function mapStateToProps(state) {
	return {
		edit_department: state.settings_admin.edit_department,
		vehicle_columns: state.settings_departments.vehicle_columns,
		departments: state.settings_departments.original_departments,
		related_installations: state.settings_dealerships.related_installations,
		work_flows: state.settings_departments.work_flows,
		set_dealerships: state.settings_dealerships.set_dealerships,
	};
}
export default connect(mapStateToProps, mappedActions)(EditDepartmentSettings);
