import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import html2canvas from 'html2canvas';
import domtoimage from 'dom-to-image';
import jsPDF from 'jspdf';
import {
	getMonthDayYear,
} from '../../utils/dateFunctions';

class UsedCarStockInSheet extends Component {
	constructor(props) {
		super(props);

		this.state = {
			addPartOrderError: '', fwd: false, rwd: false, awd: false, fourfour: false, fourtwo: false, cloth: false, leather: false, automatic: false, fivespeed: false, sixspeed: false,
		};
		this.onChange = this.onChange.bind(this);
		this.onClick = this.onClick.bind(this);
		this.onPrint = this.onPrint.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClick(e) {
		const name = e.target.dataset.name;
		this.setState({ [name]: !this.state[name] });
	}

	onPrint(e) {
		const { vehicle } = this.props;
		const print_page = document.getElementById('stock-form');
		domtoimage.toPng(print_page)
			.then((canvasOrDataUrl) => {
				if (typeof canvasOrDataUrl == 'string') {
					const pdf = new jsPDF();
				    pdf.addImage(canvasOrDataUrl, 'PNG', 0, 0);
				    pdf.save('stock_in.pdf');
				} else {
					document.body.appendChild(canvasOrDataUrl);
				}
			})
			.catch((error) => {
				console.log('oops, something went wrong!', error);
			});
	}

	render() {
		const self = this;

		return (<div className="container" id="stock-form" style={{ width: '800', fontSize: '12px' }}>
			<button onClick={this.onPrint} className="btn btn-success float-right">Print</button>
			<div className="col-xs-12">
				<p className="text-center" style={{ textAlign: 'center' }}>USED CAR STOCK-IN-SHEET</p>
			</div>
			<table style={{ width: '100%' }} cellPadding="1">
				<tr>
					<td style={{ width: '170px' }}>PURCHASED DATE</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="date" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>

				<tr>
					<td>PURCHASED AMOUNT</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>PURCHASED FROM</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr style={{ height: '30px' }}>
					<td />
					<td />
				</tr>
				<tr>
					<td>YEAR</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>MAKE</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>MODEL</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>NUMBER OF DOORS</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr style={{ height: '30px' }}>
					<td />
					<td />
				</tr>
				<tr>
					<td>VIN NUMBER</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>CYLINDERS</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>COLOR</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr>
					<td>MILEAGE</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
				<tr style={{ height: '30px' }}>
					<td />
					<td />
				</tr>
			</table>
			<table style={{ width: '100%' }} cellPadding="5">
				<tr className="pad-top-1">
					<td style={{ padding: '10px 0' }}>DRIVE TRAIN</td>
					<td>
						<span onClick={this.onClick} data-name={'fwd'} className={this.state.fwd ? 'addborder circled' : 'addborder'}>&nbsp;FWD&nbsp;</span>
						<span onClick={this.onClick} data-name={'rwd'} className={this.state.rwd ? 'addborder circled' : 'addborder'}>&nbsp;RWD&nbsp;</span>
						<span onClick={this.onClick} data-name={'awd'} className={this.state.awd ? 'addborder circled' : 'addborder'}>AWD</span>
						<span onClick={this.onClick} data-name={'fourfour'} className={this.state.fourfour ? 'addborder circled' : 'addborder'}>4X4</span>
						<span onClick={this.onClick} data-name={'fourtwo'} className={this.state.fourtwo ? 'addborder circled' : 'addborder'}>4X2</span>
					</td>
				</tr>
				<tr>
					<td style={{ padding: '10px 0' }}>INTERIOR TYPE</td>
					<td>
						<span onClick={this.onClick} data-name={'cloth'} className={this.state.cloth ? 'addborder circled' : 'addborder'}>&nbsp;CLOTH&nbsp;</span>
						<span onClick={this.onClick} data-name={'leather'} className={this.state.leather ? 'addborder circled' : 'addborder'}>&nbsp;LEATHER&nbsp;</span>
					</td>
				</tr>
				<tr>
					<td style={{ padding: '10px 0' }}>TRANSMISSION</td>
					<td>
						<span onClick={this.onClick} data-name={'automatic'} className={this.state.automatic ? 'addborder circled' : 'addborder'}>&nbsp;AUTOMATIC&nbsp;</span>
						<span onClick={this.onClick} data-name={'fivespeed'} className={this.state.fivespeed ? 'addborder circled' : 'addborder'}>&nbsp;5SPD&nbsp;</span>
						<span onClick={this.onClick} data-name={'sixspeed'} className={this.state.sixspeed ? 'addborder circled' : 'addborder'}>6SPD</span>
					</td>
				</tr>
				<tr style={{ height: '30px' }}>
					<td />
					<td />
				</tr>
			</table>
			<table style={{ width: '100%' }}>
				<tr>
					<td>
BUY FEE
						<span style={{
							borderBottom: 'thin solid black', width: '78%', fontStyle: 'italic', display: 'inline-block', paddingRight: '0px', float: 'right',
						}}
						>
							<input type="text" className="dmsFormInputs" />
						</span>
					</td>
					<td>
PAYABLE TO
						<span style={{
							borderBottom: 'thin solid black', width: '78%', fontStyle: 'italic', display: 'inline-block', paddingRight: '0px', float: 'right',
						}}
						>
							<input type="text" className="dmsFormInputs" />
						</span>
					</td>
				</tr>
			</table>
			<table style={{ width: '100%' }}>
				<tr>
					<td style={{ width: '75px', fontWeight: 'italic' }}>NOTES</td>
					<td style={{ borderBottom: 'thin solid black' }}>
						<input type="text" className="dmsFormInputs" style={{ width: '250px', fontStyle: 'italic', border: 0 }} />
					</td>
				</tr>
			</table>
		</div>);
	}
}

function mapStateToProps(state) {
	return {};
}

export default connect(mapStateToProps)(UsedCarStockInSheet);
