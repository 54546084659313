import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import InputTypes from '../../../inputs/InputTypes';
import AllInputs from '../../../inputs/AllInputs';
import {
	lookupDepartmentByName,
	vsearch,
} from '../../../actions/lookups';
import {
	setCustomModalTab,
} from '../../../actions/apiSetInfo';
import {
	processDepartments,
} from '../../../actions/apiGetInfo';
import {
	addVehicleProperty,
	updateVehicleDepartment,
} from '../../../actions/AllUpdates';

class ModalContainer extends Component {
	constructor(props) {
		super(props);
		this.onChange = this.onChange.bind(this);
		this.openModal = this.openModal.bind(this);
		this.afterOpenModal = this.afterOpenModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.onChangeCheckedNeeded = this.onChangeCheckedNeeded.bind(this);
		this.onChangeCheckedCompleted = this.onChangeCheckedCompleted.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.state = { modalIsOpen: this.props.status };
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onBlur(e) {
		const errorType = 'updateVehicleError';
		const vehicle_id = e.target.dataset.parentid;
		const vehicle = this.props.vehicle;
		const key = e.target.name;
		const value = e.target.value;
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};

		let department = [];

		if ((key == 'on site' || key == 'auto image - detail' || key == 'unique auto') && value == 'completed') {
			const dept_four = this.props.lookupDepartmentByName('stage four - detail assignment');
			if (parseInt(dept_four.id) === parseInt(vehicle.department_id)) {
				department = this.props.lookupDepartmentByName('stage five - anderson auto interior');
			}
		}

		Promise.all([
			this.props.addVehicleProperty(data, errorType),
		]).then(() => {
			this.props.processDepartments([vehicle.department_id]);
		}).then(() => {
			if (department.id) {
				this.props.updateVehicleDepartment(vehicle_id, 'department_id', parseInt(department.id));
			}
		});
	}

	onChangeCheckedNeeded(e) {
		const errorType = 'updateVehicleError';
		const vehicle_id = this.props.vehicle_id;
		const vehicle = this.props.vehicle;
		const key = e.target.name;
		const value = e.target.checked ? 'needed' : '';
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};

		Promise.all([
			this.props.addVehicleProperty(data, errorType),
		]).then(() => {
			this.props.processDepartments([vehicle.department_id]);
		}).then(() => {
			const { vehicle_filter } = self.props;
			if (vehicle_filter.type === 'searched') {
				this.props.vsearch(vehicle_filter.value);
			}
		});
	}

	onChangeCheckedCompleted(e) {
		const errorType = 'updateVehicleError';
		const vehicle_id = this.props.vehicle_id;
		const vehicle = this.props.vehicle;
		const key = e.target.name;
		const value = e.target.checked ? 'completed' : '';
		const uid = this.props.current_user.user;
		const user_name = this.props.current_user.user_name || this.props.current_user.email;

		const data = {
			vehicle_id,
			key,
			value,
			uid,
			user_name,
		};

		let department = [];

		if ((key == 'on site' || key == 'auto image - detail' || key == 'unique auto') && value == 'completed') {
			const dept_four = this.props.lookupDepartmentByName('stage four - detail assignment');
			if (parseInt(dept_four.id) === parseInt(vehicle.department_id)) {
				department = this.props.lookupDepartmentByName('stage five - anderson auto interior');
			}
		}

		Promise.all([
			this.props.addVehicleProperty(data, errorType),
		]).then(() => {
			this.props.processDepartments([vehicle.department_id]);
		}).then(() => {
			const { vehicle_filter } = self.props;
			if (vehicle_filter.type === 'searched') {
				this.props.vsearch(vehicle_filter.value);
			}
		});
	}

	openModal() {
		this.props.setCustomModalTab(this.props.tab, this.props.vehicle.id, true);
	}

	afterOpenModal() {
	}

	closeModal(e) {
		e.stopPropagation();
		this.props.setCustomModalTab({}, 0, false);
		this.setState({ modalIsOpen: false });
	}

	render() {
		const tab = this.props.tab || [];
		const vehicle = this.props.vehicle;
		const tab_properties = tab.tab_properties || [];
		let automax = '';
		let automax2 = '';
		const self = this;
		if (self.props.installation.subdomain === 'automaxatlanta') {
			const wheel_array = [{ name: 'wheel repair' }, { name: 'Wheel Repair FL' }, { name: 'Wheel Repair FR' }, { name: 'Wheel Repair BL' }, { name: 'Wheel Repair BR' }];
			automax = (
				wheel_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name });

					if (typeof vehicle_column !== 'undefined') {
						const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

						return <span>
							<label className="space-top-1">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								onChange={this.onChange}
								onBlur={this.onBlur}
								username={colObject.user_name}
								value={this.state[tab_property.name] || colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
							/>
						</span>;
					}
				})

			);

			const tire_array = [{ name: 'tires' }, { name: 'Tires FL' }, { name: 'Tires FR' }, { name: 'Tires BL' }, { name: 'Tires BR' }];
			automax2 = (
				tire_array.map((tab_property) => {
					const vehicle_column = _.find(this.props.vehicle_columns, { name: tab_property.name });

					if (typeof vehicle_column !== 'undefined') {
						const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

						return <span>
							<label className="space-top-1">{tab_property.name}</label>
							<AllInputs
								name={vehicle_column.name}
								label={vehicle_column.name}
								onChange={this.onChange}
								onBlur={this.onBlur}
								username={colObject.user_name}
								value={this.state[tab_property.name] || colObject.value || ''}
								type={vehicle_column.input_name}
								options={vehicle_column.options}
								checked={colObject.value || ''}
								updatetype={'vehicleproperty'}
								key={`vehicle_${vehicle.id}tab_property${colObject.id}`}
								parentid={vehicle.id}
								rowid={colObject.id || ''}
								uid={colObject.uid}
								updated_at={colObject.updated_at}
								icon={vehicle_column.icon}
								userStamp={this.props.userStamp}
								setdisabled={false}
							/>
						</span>;
					}
				})

			);
		}

		return <span>
			<Modal
				isOpen={this.props.status}
				onAfterOpen={this.afterOpenModal}
				onRequestClose={this.closeModal}
				shouldCloseOnOverlayClick
				contentLabel="PickColor"
				contentLabel="Checkboxes"
				ariaHideApp={false}
				className="d-flex justify-content-center p-2"
				overlayClassName="overlay"
			>
				<div className="card text-center">
					<div className="card-text bg-light">

						{tab_properties.map((tab_property) => {
							const vehicle_column = _.find(this.props.vehicle_columns, { id: tab_property.vehicle_column_id });
							if (self.props.installation.subdomain.includes('automaxatlanta') && (vehicle_column.name === 'tires' || vehicle_column.name === 'wheel repair')) {
								return false;
							}

							if (typeof vehicle_column !== 'undefined') {
								const colObject = _.find(vehicle.vehicle_properties, { key: vehicle_column.name }) || '';

								return <AllInputs
									name={vehicle_column.name}
									label={vehicle_column.name}
									onChange={this.onChange}
									onBlur={this.onBlur}
									username={colObject.user_name}
									value={colObject.value || ''}
									type={vehicle_column.input_name}
									options={vehicle_column.options}
									checked={colObject.value || ''}
									parentid={this.props.vehicle_id}
									rowid={colObject.id || ''}
									uid={colObject.uid}
									updated_at={colObject.updated_at}
									updatetype="vehicleproperty"
									icon={vehicle_column.icon}
									userStamp={this.props.userStamp}
									setdisabled={false}
								/>;
							}
						})}

						<div className="card card-body">{automax}</div>
						<div className="card card-body">{automax2}</div>
					</div>
					<div className="card-footer">
						<button className="btn btn-sm btn-danger float-left" onClick={this.closeModal}>Close</button>
					</div>
				</div>
			</Modal>
         </span>;
	}
}

const mappedActions = {
	lookupDepartmentByName,
	setCustomModalTab,
	addVehicleProperty,
	updateVehicleDepartment,
	processDepartments,
	vsearch,
};

function mapStateToProps(state) {
	return {
		status: state.settings_refresh.custom_modal_tab.status,
		tab: state.settings_refresh.custom_modal_tab.tab,
		vehicle_id: state.settings_refresh.custom_modal_tab.vehicle_id,
		vehicle: state.settings_refresh.custom_modal_tab.vehicle,
		installation: state.settings_dealerships.installation,
		vehicle_columns: state.settings_departments.vehicle_columns,
		current_user: state.current_user,
	};
}
export default connect(mapStateToProps, mappedActions)(ModalContainer);
