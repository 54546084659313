import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import VehicleDisplay from './VehicleDisplay/VehicleDisplay.js'
import ModalContainer from './ModalContainer';
import './VehicleList.scss'

class VehicleList extends PureComponent {
	constructor(props) {
		super(props);

		this.state = { vehicles: this.props.active_vehicles };
	}

	render() {
		const vehicles = this.props.active_vehicles;

		return (
			<div id="page-vehicles-wrapper" className={'container-fluid pad-top-0 pad-left-0 bg-custom-'}>
				{(this.props.update_status == 'first_load') ? <div className="container bg-dark">
					<h3 className="text-center">
Vehicles and data are being loaded.
						<img src="https://cdn.ipacketrecon.com/images/loading.gif" className="mx-auto d-block col-md-2" />
					</h3>
				</div> : ''}
				{vehicles.map(vehicle => <VehicleDisplay vehicle={vehicle} key={`vid_${vehicle.id}`} />)}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		active_vehicles: state.settings_refresh.active_vehicles,
	};
}
export default connect(mapStateToProps)(VehicleList);
