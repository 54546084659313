import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import TextFieldGroup from './TextFieldGroupNoLabel';
import Dropdown from './Dropdown';
import { setEditFilter } from '../actions/apiSetInfo';
import { updateFilter } from '../actions/apiFilters';

const activeOptions = ['true', 'false'];

class EditFilterBox extends Component {
	constructor(props) {
		super(props);

		this.state = { filter_name: this.props.item.filter_name, filter_value: this.props.item.filter_value };
		this.onChange = this.onChange.bind(this);
		this.onClose = this.onClose.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onBlur = this.onBlur.bind(this);
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onClose() {
		this.props.setEditFilter();
	}

	onBlur(e) {
		const name = e.target.name;
		const { id } = this.props.item;
		var value = e.target.value;
		const errorType = 'updateFilter';
		if (name === 'name') {
			value = value.toLowerCase();
		}
		const data = {
			id,
			[name]: value,
		};

		this.props.updateFilter(id, data, errorType);
	}

	onDelete() {
		this.onClose();
		this.props.deleteFilter(this.props.item.id, 'deleteFilter');
	}

	selectColumns() {
		const columns = this.vehicle_columns();

		const options = columns.map((option) => {
			const obj = { value: option.id, label: option.name };
			return obj;
		});
		return options;
	}

	allFilters() {
		const options = this.props.all_filters.map((option) => {
			const obj = { value: option.id, label: `${option.filter_name} ( ${option.filter_target} ${option.filter_operator} ${option.filter_value} )` };
			return obj;
		});

		const obj = { value: 'Create New Filter', label: 'Create New Filter' };
		options.push(obj);
		return options;
	}

	selectData(value) {
		const options = this.props.vehicle_columns.map((option) => {
			let selected = false;
			if (option.name === value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			} else {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectComparison(value) {
		const comparisonArray = [{ id: 1, name: 'Greater Than', label: 'Greater Than' },
			{ id: 2, name: 'Greater or Equal', label: 'Greater or Equal' }, { id: 3, name: 'Equals', label: 'Equals' }, { id: 4, name: 'Not Equals', label: 'Not Equals' },
			{ id: 5, name: 'Less Than', label: 'Less Than' }, { id: 6, name: 'Less or Equal', label: 'Less or Equal' }, { id: 7, name: 'Contains', label: 'Contains' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.name === value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, value: option.name, label: option.label, selected,
				};
			} else {
				var obj = {
					id: option.id, value: option.name, label: option.label, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectDataType(value) {
		const comparisonArray = [{ id: 1, name: 'number', label: 'Number' },
			{ id: 2, name: 'condition', label: 'Condition' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.name === value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			} else {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			}
			return obj;
		});
		return options;
	}

	selectDataOrigin(value) {
		const comparisonArray = [{ id: 1, name: 'vehicle', label: 'Vehicle' },
			{ id: 2, name: 'vehicle_meta', label: 'Vehicle Meta' }];

		const options = comparisonArray.map((option) => {
			let selected = false;
			if (option.name === value) {
				selected = true;
			}
			if (typeof option.name !== 'undefined') {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			} else {
				var obj = {
					id: option.id, value: option.name, label: option.name, selected,
				};
			}
			return obj;
		});
		return options;
	}

	render() {
		const self = this;
		const { item } = this.props;
		return (

			<div className="card space-top-2">

				<div className="card-header bg-info text-white col-md-12 text-capitalize text-center">
					Edit
					{' '}
					{item.filter_name}
				</div>
				<ul className="list-group">
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>Fitler Name:</strong></label>
							</div>
							<div className={'col-md-6'}>
								<TextFieldGroup
									field="filter_name"
									label="Filter Name"
									onChange={this.onChange}
									value={this.state.filter_name}
									type="text"
									key={'add_filter_name'}
									name={'filter_name'}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>Target Data:</strong></label>
							</div>
							<div className={'col-md-6'}>

								<Dropdown
									{...this.props}
									titleHelper="Select filter_target"
									title="Select filter_target"
									list={this.selectData(item.filter_target)}
									update_type={'all_filter'}
									value={item.filter_target}
									all_filter_id={item.id}
									name={'filter_target'}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>FilterType:</strong></label>
							</div>
							<div className={'col-md-6'}>
								<Dropdown
									{...this.props}
									titleHelper="Select filter_type"
									title="Select filter_type"
									list={this.selectDataType(item.filter_type)}
									update_type={'all_filter'}
									value={item.filter_type}
									all_filter_id={item.id}
									name={'filter_type'}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>DataSource:</strong></label>
							</div>
							<div className={'col-md-6'}>
								<Dropdown
									{...this.props}
									titleHelper="Select Work Flow"
									title="Select Work Flow"
									list={this.selectDataOrigin(item.data_origin)}
									update_type={'all_filter'}
									value={item.data_origin}
									all_filter_id={item.id}
									name={'data_origin'}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>Operator:</strong></label>
							</div>
							<div className={'col-md-6'}>
								<Dropdown
									{...this.props}
									titleHelper="Select filter_operator"
									title="Select filter_operator"
									name={'filter_operator'}
									list={this.selectComparison(item.filter_operator)}
									update_type={'all_filter'}
									value={item.filter_operator}
									all_filter_id={item.id}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'}>
								<label className={'pad-left-2'}><strong>Equals:</strong></label>
							</div>
							<div className={'col-md-6'}>
								<TextFieldGroup
									field="filter_value"
									name="filter_value"
									label="Value"
									onChange={this.onChange}
									onBlur={this.onBlur}
									value={this.state.filter_value}
									type="text"
									key={'add_filter_value'}
								/>
							</div>
						</div>
					</li>
					<li className={'list-group-item'}>
						<div className={'row'}>
							<div className={'col-md-6'} />
							<div className={'col-md-6'}>
								<button className="btn btn-danger" onClick={this.onDelete}>Delete</button>
							</div>
						</div>
					</li>
				</ul>
			</div>
		);
	}
}

const mappedActions = {
	setEditFilter,
	updateFilter,
};

function mapStateToProps(state) {
	return {
		input_types: state.settings_departments.input_types,
		departments: state.settings_departments.departments,
		all_filters: state.settings_site.all_filters,
		edit_filter: state.settings_site.edit_filter,
		vehicle_columns: state.settings_departments.vehicle_columns,
	};
}
export default connect(mapStateToProps, mappedActions)(EditFilterBox);
