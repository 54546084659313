import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import _ from 'lodash';
import FileUploadButton from '../../../../inputs/FileUploadButton';
import {
	getDateTime,
	userStamp,
} from '../../../../utils/dateFunctions';

import {
	deleteWorkItem,
	updateWorkItem,
} from '../../../../actions/apiWorkItems';
import {
	sendEstimate,
} from '../../../../actions/apiEstimates';

const fields = [{ name: 'description', label: 'Description', type: 'textarea' },
	{ name: 'recommended', label: 'Recommended', type: 'checkbox' },
	{ name: 'required', label: 'Required', type: 'checkbox' },
	{ name: 'labor', label: 'Labor', type: 'text' },
	{ name: 'oem', label: 'OEM', type: 'text' },
	{ name: 'non_oem', label: 'Non OEM', type: 'text' },
	{ name: 'work_approved', label: 'Work Approved', type: 'checkbox' },
	{ name: 'work_declined', label: 'Work Declined', type: 'checkbox' },
	{ name: 'item_completed', label: 'Item Completed', type: 'checkbox' }];

class WorkItem extends PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			addDeleteEstimateError: '',
			vehicle_id: this.props.active_vehicle.id,
			description: this.props.work_item.description,
			recommended: this.props.work_item.recommended,
			required: this.props.work_item.required,
			labor: this.props.work_item.labor || 0,
			oem: this.props.work_item.oem || 0,
			non_oem: this.props.work_item.non_oem || 0,
			work_approved: this.props.work_item.work_approved,
			work_declined: this.props.work_item.work_declined,
			item_completed: this.props.work_item.item_completed,
		};
		this.onChange = this.onChange.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onFocus = this.onFocus.bind(this);
		this.onChangeInput = this.onChangeInput.bind(this);
		this.onChangeTextArea = this.onChangeTextArea.bind(this);

		this.onChangeChecked = this.onChangeChecked.bind(this);
		this.onDelete = this.onDelete.bind(this);
		this.onSend = this.onSend.bind(this);
		this.onApprove = this.onApprove.bind(this);
		this.onDeny = this.onDeny.bind(this);
	}

	onChangeTextArea(event) {
		this.setState({ value: event.target.value });
	}

	onBlur(e) {
		const self = this;
		const errorType = 'updateWorkItemError';
		const { id } = self.props.work_item;
		const { value } = e.target;
		const user_name = self.props.current_user.user_name || self.props.current_user.email;

		let data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			[e.target.name]: value,
		};

		if (e.target.name === 'labor') {
			var key1 = 'labor_completed_by';
			var key2 = 'labor_completed_datetime';

			data = {
				id,
				vehicle_id: this.props.active_vehicle.id,
				[e.target.name]: value,
				[key1]: user_name,
				[key2]: getDateTime(),
			};
		} else if (e.target.name === 'oem') {
			var key1 = 'oem_completed_by';
			var key2 = 'oem_completed_datetime';

			data = {
				id,
				vehicle_id: this.props.active_vehicle.id,
				[e.target.name]: value,
				[key1]: user_name,
				[key2]: getDateTime(),
			};
		} else if (e.target.name === 'non_oem') {
			var key1 = 'non_oem_completed_by';
			var key2 = 'non_oem_completed_datetime';

			data = {
				id,
				vehicle_id: this.props.active_vehicle.id,
				[e.target.name]: value,
				[key1]: user_name,
				[key2]: getDateTime(),
			};
		}

		this.props.updateWorkItem(id, data, errorType);
	}

	onFocus(e) {
		this.setState({ [e.target.name]: '' });
	}

	onChangeChecked(e) {
		const self = this;
		const errorType = 'updateWorkItemError';
		const { id } = self.props.work_item;

		const value = e.target.checked ? 'true' : '';
		this.setState({ [e.target.name]: value });

		const user_name = self.props.current_user.user_name || self.props.current_user.email;

		if (e.target.name === 'recommended') {
			var key1 = 'recommended_by';
			var key2 = 'recommended_datetime';
		} else if (e.target.name === 'required') {
			var key1 = 'required_by';
			var key2 = 'required_datetime';
		} else if (e.target.name === 'work_approved') {
			var key1 = 'work_approved_by';
			var key2 = 'work_approved_datetime';
		} else if (e.target.name === 'work_declined') {
			var key1 = 'work_declined_by';
			var key2 = 'work_declined_datetime';
		} else if (e.target.name === 'item_completed') {
			var key1 = 'item_completed_by';
			var key2 = 'item_completed_datetime';
		}

		const data = {
			id,
			vehicle_id: this.props.active_vehicle.id,
			[e.target.name]: value,
			[key1]: user_name,
			[key2]: getDateTime(),
		};

		this.props.updateWorkItem(id, data, errorType);
	}

	onChangeInput(e) {
		this.setState({ [e.target.name]: e.target.value });
	}

	onChange(e) {
		this.setState({ [e.target.name]: e.target.value });
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			description: this.state.description,
			recommended: this.state.recommended,
			required: this.state.required,
			labor: parseInt(this.state.labor),
			oem: parseInt(this.state.oem),
			non_oem: parseInt(this.state.non_oem),
			work_approved: this.state.work_approved,
			work_declined: this.state.work_declined,
			item_completed: this.state.item_completed,
		};

		this.props.updateWorkItem(this.props.work_item.id, data, 'addWorkItemError');
	}

	onDelete(e) {
		if (this.state.work_declined !== 'true' && this.state.work_approved !== 'true') {
			this.props.deleteWorkItem(this.props.work_item.id, 'addDeleteEstimateError');
		}
	}

	onSend(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			description: this.state.description,
			recommended: this.state.recommended,
			required: this.state.required,
			labor: parseInt(this.state.labor),
			oem: parseInt(this.state.oem),
			non_oem: parseInt(this.state.non_oem),
			work_approved: this.state.work_approved,
			work_declined: this.state.work_declined,
			item_completed: this.state.item_completed,
		};

		this.props.updateWorkItem(this.props.work_item.id, data, 'addWorkItemError');
	}

	onApprove(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			requested_by_uid: this.state.requested_by_uid,
			estimate: this.state.estimate,
			ro_status: 'approved',
			approved_by_uid: this.props.current_user.user,
			approved_date: getDateTime(),
		};

		this.props.sendEstimate(this.props.work_item.id, data, 'addEstimateError');
	}

	onDeny(e) {
		const data = {
			vehicle_id: this.props.active_vehicle.id,
			ro_number: this.state.ro_number,
			requested_by_uid: this.state.requested_by_uid,
			description: this.state.description,
			estimate: this.state.estimate,
			actual: this.state.actual,
			ro_status: 'denied',
			declined_by_uid: this.props.current_user.user,
			declined_date: getDateTime(),
			declined_reason: this.state.declined_reason,
			ro_email_link_counter_declined: this.state.ro_email_link_counter_declined,
			ro_email_link_counter_approved: this.state.ro_email_link_counter_approved,

		};

		this.props.sendEstimate(this.props.work_item.id, data, 'addEstimateError');
	}

	render() {
		self = this;
		var setdisabledService = true;

		var setdisabledEveryone = true;

		if (setdisabledService) {
			var allowedArray = ['super_admin', 'admin', 'service', 'service_manager', 'management'];

			if (allowedArray.includes(this.props.current_user.position)) {
				var setdisabledService = false;
			}
		}

		if (setdisabledEveryone) {
			var allowedArray = ['super_admin', 'admin'];

			if (allowedArray.includes(this.props.current_user.position)) {
				var setdisabledEveryone = false;
			}
		}

		const { work_item } = self.props;

		const fields = [{ name: 'description', label: 'Description', type: 'textarea' },
			{ name: 'recommended', label: 'Recommended', type: 'checkbox' },
			{ name: 'required', label: 'Required', type: 'checkbox' },
			{ name: 'labor', label: 'Labor', type: 'text' },
			{ name: 'oem', label: 'OEM', type: 'text' },
			{ name: 'non_oem', label: 'Non OEM', type: 'text' },
			{ name: 'work_approved', label: 'Work Approved', type: 'checkbox' },
			{ name: 'work_declined', label: 'Work Declined', type: 'checkbox' },
			{ name: 'item_completed', label: 'Item Completed', type: 'checkbox' }];

		return (
			<tr className="text-center">
				<td>
					<input
						onChange={this.onChangeChecked}
						type={'checkbox'}
						name={'recommended'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_reco`}
						data-id={this.props.work_item.id}
						checked={this.state.recommended == 'true' ? 'checked' : ''}
						className={classnames('text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.recommended == 'true' ? this.props.work_item.recommended_by : ''}</small>
					<br />
					<small>{this.state.recommended == 'true' ? userStamp(this.props.work_item.recommended_datetime) : ''}</small>
				</td>
				<td>
					<input
						onChange={this.onChangeChecked}
						type={'checkbox'}
						name={'required'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_required`}
						data-id={this.props.work_item.id}
						checked={this.state.required == 'true' ? 'checked' : ''}
						className={classnames('text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.required == 'true' ? this.props.work_item.required_by : ''}</small>
					<br />
					<small>{this.state.required == 'true' ? userStamp(this.props.work_item.required_datetime) : ''}</small>
				</td>

            	<td>

					<textarea
						onBlur={this.onBlur}
						onChange={this.onChangeInput}
						value={this.state.description || ''}
						name={'description'}
						data-id={work_item.id}
						key={`ds_${work_item.id}_${this.props.active_vehicle.id}_des`}
						className={classnames('form-control', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
				</td>

				<td>
					<input
						onChange={this.onChangeInput}
						onBlur={this.onBlur}
						type={'number'}
						name={'labor'}
						onFocus={this.onFocus}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_labor`}
						data-id={this.props.work_item.id}
						value={this.state.labor}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.labor >= 1 ? this.props.work_item.labor_completed_by : ''}</small>
					<br />
					<small>{this.props.work_item.labor_completed_datetime !== null ? userStamp(this.props.work_item.labor_completed_datetime) : ''}</small>
				</td>
				<td>
					<input
						onChange={this.onChangeInput}
						onBlur={this.onBlur}
						type={'number'}
						onFocus={this.onFocus}
						name={'oem'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_oem`}
						data-id={this.props.work_item.id}
						value={this.state.oem}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.oem >= 1 ? this.props.work_item.oem_completed_by : ''}</small>
					<br />
					<small>{this.props.work_item.oem_completed_datetime !== null ? userStamp(this.props.work_item.oem_completed_datetime) : ''}</small>
				</td>
				<td>
					<input
						onChange={this.onChangeInput}
						onBlur={this.onBlur}
						onFocus={this.onFocus}
						type={'number'}
						name={'non_oem'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_non_oem`}
						data-id={this.props.work_item.id}
						value={this.state.non_oem}
						className={classnames('form-control text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.non_oem >= 1 ? this.props.work_item.non_oem_completed_by : ''}</small>
					<br />
					<small>{this.props.work_item.non_oem_completed_datetime !== null ? userStamp(this.props.work_item.non_oem_completed_datetime) : ''}</small>
				</td>

				<td>
					<input
						onChange={this.onChangeChecked}
						type={'checkbox'}
						name={'work_approved'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_work_approved`}
						data-id={this.props.work_item.id}
						checked={this.state.work_approved == 'true' ? 'checked' : ''}
						className={classnames('text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledEveryone ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.work_approved == 'true' ? this.props.work_item.work_approved_by : ''}</small>
					<br />
					<small>{this.state.work_approved == 'true' ? userStamp(this.props.work_item.work_approved_datetime) : ''}</small>
				</td>
				<td>
					<input
						onChange={this.onChangeChecked}
						type={'checkbox'}
						name={'work_declined'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_work_declined`}
						data-id={this.props.work_item.id}
						checked={this.state.work_declined == 'true' ? 'checked' : ''}
						className={classnames('text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledEveryone ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.work_declined == 'true' ? this.props.work_item.work_declined_by : ''}</small>
					<br />
					<small>{this.state.work_declined == 'true' ? userStamp(this.props.work_item.work_declined_datetime) : ''}</small>
				</td>
				<td>
					<input
						onChange={this.onChangeChecked}
						type={'checkbox'}
						name={'item_completed'}
						key={`ds_${this.props.work_item.id}_${this.props.active_vehicle.id}_item_completed`}
						data-id={this.props.work_item.id}
						checked={this.state.item_completed == 'true' ? 'checked' : ''}
						className={classnames('text-center', { 'form-control-danger': this.props.error })}
						disabled={setdisabledService ? 'disabled' : ''}
					/>
					<br />
					<small>{this.state.item_completed == 'true' ? this.props.work_item.item_completed_by : ''}</small>
					<br />
					<small>{this.state.item_completed == 'true' ? userStamp(this.props.work_item.item_completed_datetime) : ''}</small>
				</td>

				<td><button className="btn btn-sm btn-danger" onClick={self.onDelete}>X</button></td>
			</tr>
		);
	}
}

const mappedActions = {
	deleteWorkItem,
	updateWorkItem,
	sendEstimate,
};

function mapStateToProps(state) {
	return { current_user: state.current_user, active_vehicle: state.settings_refresh.active_vehicle };
}
export default connect(mapStateToProps, mappedActions)(WorkItem);
