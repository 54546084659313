import _ from 'lodash';
import * as types from './types';
import * as getInfo from './apiGetInfo';
import Api from '../api/reconApi.js';
import setAuthorizationToken from '../api/setAuthorizationToken';
import * as ApiReports from './apiReports';
import * as AllMutates from './AllMutates';

export function update_loading_status(update_status) {
	return {
		type: types.UPDATE_STATUS,
		update_status,
	};
}

export function setInactiveStatus(status) {
	return {
		type: types.SET_INACTIVE_STATUS,
		status,
	};
}

export function setCancelOldRequests(status) {
	return {
		type: types.SET_CANCEL_OLD_REQUESTS,
		status,
	};
}

export function setInactiveStatusInterval(id) {
	return {
		type: types.SET_INACTIVE_STATUS_INTERVAL,
		id,
	};
}

export function setReportDepartmentData(report_data) {
	return {
		type: types.SET_REPORT_DEPARTMENT_DATA,
		report_data,
	};
}

export function setEmailReports({ email_reports }) {
	return {
		type: types.SET_EMAIL_REPORTS,
		email_reports,
	};
}

export function setReportTypes({ report_types }) {
	return {
		type: types.SET_REPORT_TYPES,
		report_types,
	};
}

export function setDealerships({ dealerships }) {
	return {
		type: types.SET_DEALERSHIPS,
		dealerships,
	};
}

export function setTimeRange(time_range) {
	return {
		type: types.SET_TIME_RANGE,
		time_range,
	};
}

export function setReportTimeRange(report_time_range) {
	return {
		type: types.SET_REPORT_TIME_RANGE,
		report_time_range,
	};
}

export function setVehicleHistories({ vehicle_histories }) {
	return {
		type: types.SET_VEHICLE_HISTORIES,
		vehicle_histories,
	};
}

export function setDealership(dealership, dealership_id) {
	return {
		type: types.SET_CURRENT_DEALERSHIP,
		dealership,
		dealership_id,
	};
}

export function setSearchedDealerships({ dealerships }) {
	return {
		type: types.SET_SEARCHED_DEALERSHIPS,
		dealerships,
	};
}

export function setNotificationSettings({ notification_settings }) {
	return {
		type: types.SET_NOTIFICATION_SETTINGS,
		notification_settings,
	};
}

export function setPartOrders({ part_orders }) {
	return {
		type: types.SET_PART_ORDERS,
		part_orders,
	};
}

export function setCurrentDealership(database, dealership, dealership_id) {
	return (dispatch) => {
		const browser = getInfo.getBrowser();
		const user = localStorage.reconUser;
		const token = localStorage.reconToken;
		const username = localStorage.reconUserName;

		Promise.all([
			setAuthorizationToken(token, user, username, database, dealership),
			dispatch(setDealership(dealership, dealership_id)),
			dispatch(getInfo.getRelatedInstallations()),
		]).then(() => dispatch(getInfo.getInstallation())).then(() => dispatch(syncInitialData()));

		return true;
	};
}

export function setCurrentUser({ user, token }) {
	return {
		type: types.SET_CURRENT_USER,
		user,
		token,
	};
}

export function setUsers(users) {
	return {
		type: types.SET_USERS,
		users,
	};
}

export function setDefaultProfiles(default_profiles) {
	return {
		type: types.SET_DEFAULT_PROFILES,
		default_profiles,
	};
}

export function setCurrentUserProfile(profile) {
	return {
		type: types.SET_CURRENT_USER_PROFILE,
		profile,
	};
}

export function setInstallation(installation) {
	return {
		type: types.SET_INSTALLATION,
		installation,
	};
}

export function setFeedLogs(feed_logs) {
	return {
		type: types.SET_FEED_LOGS,
		feed_logs,
	};
}

export function setFeedProviders(feed_providers) {
	return {
		type: types.SET_FEED_PROVIDERS,
		feed_providers,
	};
}

export function setFeedProviderRules(feed_provider_rules) {
	return {
		type: types.SET_FEED_PROVIDER_RULES,
		feed_provider_rules,
	};
}

export function setFeedProviderSettings(feed_provider_settings) {
	return {
		type: types.SET_FEED_PROVIDER_SETTINGS,
		feed_provider_settings,
	};
}

export function setFeedNotificationSettings(feed_notification_settings) {
	return {
		type: types.SET_FEED_PROVIDER_SETTINGS,
		feed_notification_settings,
	};
}

export function setFeedErrors(feed_errors) {
	return {
		type: types.SET_FEED_ERRORS,
		feed_errors,
	};
}

export function setRelatedInstallations(related_installations) {
	return {
		type: types.SET_RELATED_INSTALLATIONS,
		related_installations,
	};
}

export function setWorkFlows({ work_flows }) {
	return {
		type: types.SET_WORK_FLOWS,
		work_flows,
	};
}

export function setAllFilters({ all_filters }) {
	return {
		type: types.SET_ALL_FILTERS,
		all_filters,
	};
}

export function setDataStepTargets({ data_step_targets }) {
	return {
		type: types.SET_DATA_STEP_TARGETS,
		data_step_targets,
	};
}

export function setInputTypes({ input_types }) {
	return {
		type: types.SET_INPUT_TYPES,
		input_types,
	};
}

export function setVehicleColumns({ vehicle_columns }) {
	return {
		type: types.SET_VEHICLE_COLUMNS,
		vehicle_columns,
	};
}

export function setReportVehicles(vehicles) {
	return {
		type: types.SET_REPORT_VEHICLES,
		vehicles,
	};
}
export function setAllVehicles(vehicles) {
	return {
		type: types.SET_ALL_VEHICLES,
		vehicles,
	};
}

export function setDuplicateVins(vehicles) {
	return {
		type: types.SET_DUPLICATE_VINS,
		vehicles,
	};
}

export function setVehicles(vehicles) {
	return {
		type: types.SET_VEHICLES,
		vehicles,
	};
}

export function setSoldVehicles(vehicles) {
	return {
		type: types.SET_SOLD_VEHICLES,
		vehicles,
	};
}

export function setReportsReady(reports_ready) {
	return {
		type: types.REPORTS_READY,
		reports_ready,
	};
}
export function setReportStartDate(report_start_date) {
	return {
		type: types.SET_REPORT_START_DATE,
		report_start_date,
	};
}

export function setReportEndDate(report_end_date) {
	return {
		type: types.SET_REPORT_END_DATE,
		report_end_date,
	};
}

export function setReportDealerships(report_dealerships) {
	return {
		type: types.SET_REPORT_DEALERSHIPS,
		report_dealerships,
	};
}

export function setReportDepartments(report_departments) {
	return {
		type: types.SET_REPORT_DEPARTMENTS,
		report_departments,
	};
}

export function setReportFilters(report_filters) {
	return {
		type: types.SET_REPORT_FILTERS,
		report_filters,
	};
}

export function setReportWorkFlows(report_work_flows) {
	return {
		type: types.SET_REPORT_WORK_FLOWS,
		report_work_flows,
	};
}

export function setReportData(report_data) {
	return {
		type: types.SET_REPORT_DATA,
		report_data,
	};
}

export function setReportDataFiltered(report_data_filtered) {
	return {
		type: types.SET_REPORT_DATA_FILTERED,
		report_data_filtered,
	};
}

export function setMonthlyReportDataFiltered(report_data_filtered) {
	return {
		type: types.SET_MONTHLY_REPORT_DATA_FILTERED,
		report_data_filtered,
	};
}

export function setDepartmentCounts(departments) {
	return {
		type: types.SET_DEPARTMENT_COUNTS,
		departments,
	};
}

export function setDepartments(departments) {
	return {
		type: types.SET_DEPARTMENTS,
		departments,
	};
}

export function setDepartmentHistories(department_histories) {
	return {
		type: types.SET_DEPARTMENT_HISTORIES,
		department_histories,
	};
}

export function setTabs({ tabs }) {
	return {
		type: types.SET_TABS,
		tabs,
	};
}

export function setDealershipColumns(dealership_columns) {
	return {
		type: types.SET_DEALERSHIP_COLUMNS,
		dealership_columns,
	};
}

export function setAllowedDepartments(departments) {
	return {
		type: types.SET_ALLOWED_DEPARTMENTS,
		departments,
	};
}

export function setDealershipDepartments(dealership_departments) {
	return {
		type: types.SET_DEALERSHIP_DEPARTMENTS,
		dealership_departments,
	};
}

export function setOriginalDepartments(departments) {
	return {
		type: types.SET_ORIGINAL_DEPARTMENTS,
		departments,
	};
}

export function setRooms(rooms) {
	return {
		type: types.SET_ROOMS,
		rooms,
	};
}

export function setRoomMessages(room_messages) {
	return {
		type: types.SET_ROOM_MESSAGES,
		room_messages,
	};
}

export function setSupportTickets(support_tickets) {
	return {
		type: types.SET_SUPPORT_TICKETS,
		support_tickets,
	};
}
export function setCurrentReportTab(report_tab) {
	return {
		type: types.SET_CURRENT_REPORT_TAB,
		report_tab,
	};
}
export function setCurrentDealerForm(dealer_form) {
	return {
		type: types.SET_CURRENT_DEALER_FORM,
		dealer_form,
	};
}

export function syncInitialData() {
	return (dispatch, getState) => {
		Promise.all([
			dispatch(getInfo.getDefaultProfiles()),
			dispatch(getInfo.getDealerSettings()),
			dispatch(getInfo.getRelatedUsers()),
			dispatch(setExtraInfo()),
		]).then(() => dispatch(setExtraInfo2()));
	};
}

export function setExtraInfo2() {
	return (dispatch, getState) => {
		Promise.all([
			dispatch(getInfo.getRooms()),
			dispatch(getInfo.getRoomMessages()),
			dispatch(getInfo.getSupportTickets()),
			dispatch(getInfo.getNotificationSettings()),
			dispatch(getInfo.getInitialReportData()),
			dispatch(getInfo.getEmailReports()),
			dispatch(getInfo.getReportTypes()),
			dispatch(getInfo.getWorkItemPresets()),
		]);
	};
}

export function syncInitialReportData() {
	return (dispatch, getState) => {
		if (getState().settings_dealerships.current_dealership !== '') {
			dispatch(getInfo.getInitialReportData());
		}
	};
}

export function syncReportData() {
	return (dispatch, getState) => {
		Promise.all([
			dispatch(getInfo.getInitialReportData()),
		]).then(() => dispatch(ApiReports.reportVehicles())).then(() => dispatch(ApiReports.filterReport())).then(() => dispatch(ApiReports.filterMonthlyReport()))
			.then(() => dispatch(setReportsReady(true)));
	};
}

export function setEditFilter(edit_filter) {
	return {
		type: types.EDIT_FILTER,
		edit_filter,
	};
}

export function setReportInfo() {
	return (dispatch, getState) => {
		if (getState().settings_dealerships.current_dealership !== '') {
			return Promise.all([dispatch(getInfo.getInitialReportData())]).then(() => {
				dispatch(ApiReports.reportVehicles());
			});
		}
	};
}

export function setReportFilterInfo() {
	return (dispatch, getState) => {
		if (getState().settings_dealerships.current_dealership !== '') {
			return Promise.all([dispatch(ApiReports.filterReport())]).then(() => {
				dispatch(ApiReports.filterMonthlyReport());
			}).then(() => {
				dispatch(setReportsReady(true));
			});
		}
	};
}

export function setReportInfo2() {
	return (dispatch, getState) => {
		if (getState().settings_dealerships.current_dealership !== '') {
			return Promise.all([dispatch(getInfo.getAllVehicles())]).then(() => {
				dispatch(getInfo.getInitialReportData());
			}).then(() => {
				dispatch(ApiReports.reportVehicles());
			})
				.then(() => {
					dispatch(ApiReports.filterReport());
					dispatch(ApiReports.filterMonthlyReport());
				})
				.then(() => dispatch(setReportsReady(true)));
		}
	};
}

export function setExtraInfo() {
	return (dispatch, getState) => {
		dispatch(getInfo.getInputTypes()).then(() => dispatch(getInfo.getVehicleColumns()),
				dispatch(getInfo.processDepartments()),
				dispatch(getInfo.getAllFilters()),
				dispatch(getInfo.getTabs()),
				dispatch(getInfo.getWorkFlows()));
	};
}

export function syncRepeatData() {
	return (dispatch, getState) => {
		dispatch(getInfo.processDepartments());
	};
}

export function setVehicleFilter(vehicle_filter, department) {
	return {
		type: types.SET_VEHICLE_FILTER,
		vehicle_filter,
		department,
	};
}

export function setPhotos({ photos }) {
	return {
		type: types.SET_PHOTOS,
		photos,
	};
}

export function setEditLocations({ edit_locations }) {
	return {
		type: types.SET_EDIT_LOCATIONS,
		edit_locations,
	};
}

export function setEditUserLocations({ edit_user_locations }) {
	return {
		type: types.SET_EDIT_USER_LOCATIONS,
		edit_user_locations,
	};
}

export function setSwitchDealership(switch_dealership) {
	return {
		type: types.SWITCH_DEALERSHIP,
		switch_dealership,
	};
}

export function setVehicleTab(tab, vehicle_id) {
	return {
		type: types.SET_VEHICLE_TAB,
		tab,
		vehicle_id,
	};
}

export function setSingleUser(single_user) {
	return {
		type: types.SET_SINGLE_USER,
		single_user,
	};
}

export function setPerUser(user) {
	return {
		type: types.SET_PER_USER,
		user,
	};
}

export function setSingleEstimate({ estimates }) {
	return {
		type: types.SET_SINGLE_ESTIMATE,
		estimates,
	};
}
export function setSingleEstimateUser({ user }) {
	return {
		type: types.SET_SINGLE_ESTIMATE_USER,
		user,
	};
}

export function setSingleDealership(single_dealership) {
	return {
		type: types.SINGLE_DEALERSHIP,
		single_dealership,
	};
}

export function editWorkFlows(edit_work_flows) {
	return {
		type: types.EDIT_WORK_FLOWS,
		edit_work_flows,
	};
}

export function setCurrentReport(current_report) {
	return {
		type: types.SET_CURRENT_REPORT,
		current_report,
	};
}

export function setCustomModalTab(tab, vehicle_id, vehicle, status) {
	return {
		type: types.SET_CUSTOM_MODAL_TAB,
		tab,
		vehicle_id,
		vehicle,
		status,
	};
}

export function setIncludeSold(include_sold) {
	return {
		type: types.INCLUDE_SOLD,
		include_sold,
	};
}

export function setVehiclePageName(page_name) {
	return {
		type: types.VEHICLE_PAGE_NAME,
		page_name,
	};
}

export function setVehiclePageSize(page_size) {
	return {
		type: types.VEHICLE_PAGE_SIZE,
		page_size,
	};
}

export function setVehiclePageNumber(page_number) {
	return {
		type: types.VEHICLE_PAGE_NUMBER,
		page_number,
	};
}

export function setVehiclePageTotal(page_total) {
	return {
		type: types.VEHICLE_PAGE_TOTAL,
		page_total,
	};
}

export function setVehicleVehicleTotal(vehicle_total) {
	return {
		type: types.VEHICLE_PAGE_VEHICLE_TOTAL,
		vehicle_total,
	};
}

export function setSortByData(sort_data) {
	return {
		type: types.SET_SORT_BY_DATA,
		sort_data,
	};
}

export function setSearchedUsers({ searched_users }) {
	return {
		type: types.SET_SEARCHED_USERS,
		searched_users,
	};
}

export function setActiveParts({ part_orders }) {
	return {
		type: types.SET_ACTIVE_PART_ORDERS,
		part_orders,
	};
}

export function setActiveAppraisals({ appraisals }) {
	return {
		type: types.SET_ACTIVE_APPRAISALS,
		appraisals,
	};
}

export function setUsersTab(users_tab) {
	return {
		type: types.SET_USERS_TAB,
		users_tab,
	};
}

export function setCurrentTab(container) {
	return {
		type: types.SET_CURRENT_TAB,
		container,
	};
}

export function setEditDepartmentCurrentTab(container) {
	return {
		type: types.SET_EDIT_DEPARTMENT_CURRENT_TAB,
		container,
	};
}

export function setCurrentDefaultProfile(id) {
	return (dispatch, getState) => {
		const newArray = getState().settings_users.default_profiles;
		const default_profile = _.find(newArray, {
			id: parseInt(id, 10),
		});
		dispatch(setCurrentDefaultProfileFull(default_profile));
	};
}

export function setViewAllDepartments(default_profile_id) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		departments.map((department) => {
			const key = department.name;
			const data = {
				default_profile_id,
				key,
				value: 'true',
			};
			Api.postData('profile_permissions', data);
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}

export function setMoveAllDepartments(default_profile_id) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		departments.map((department) => {
			const key = `allowed_move_${department.name}`;
			const data = {
				default_profile_id,
				key,
				value: 'true',
			};
			Api.postData('profile_permissions', data);
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}
export function unsetMoveAllDepartments(default_profile_id) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		departments.map((department) => {
			const key = `allowed_move_${department.name}`;
			const data = {
				default_profile_id,
				key,
				value: 'false',
			};
			Api.postData('profile_permissions', data);
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}

export function setViewAllTabs(default_profile_id) {
	return (dispatch, getState) => {
		const { all_tabs } = getState().settings_site;

		all_tabs.map((tab) => {
			const key = tab.name;
			const data = {
				default_profile_id,
				key,
				value: 'true',
			};
			if (tab.active) {
				Api.postData('profile_permissions', data);
			}
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}

export function unsetViewAllDepartments(default_profile_id) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		departments.map((department) => {
			const key = department.name;
			const data = {
				default_profile_id,
				key,
				value: 'false',
			};
			Api.postData('profile_permissions', data);
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}

export function unsetViewAllTabs(default_profile_id) {
	return (dispatch, getState) => {
		const { all_tabs } = getState().settings_site;

		all_tabs.map((tab) => {
			const key = tab.name;
			const data = {
				default_profile_id,
				key,
				value: 'false',
			};
			if (tab.active) {
				Api.postData('profile_permissions', data);
			}
		});
		dispatch(getInfo.getDefaultProfiles());
	};
}

export function setCurrentDefaultProfileFull(default_profile) {
	return {
		type: types.SET_CURRENT_DEFAULT_PROFILE,
		default_profile,
	};
}

export function setEditVehicleColumn({ edit_vehicle_column }) {
	return {
		type: types.SET_EDIT_VEHICLE_COLUMN,
		edit_vehicle_column,
	};
}

export function setEditDepartment({ edit_department }) {
	return {
		type: types.SET_EDIT_DEPARTMENT,
		edit_department,
	};
}

export function setEditDepartments(edit_departments) {
	return {
		type: types.SET_EDIT_DEPARTMENTS,
		edit_departments,
	};
}

export function setEditDepartmentProperties({ edit_department_properties }) {
	return {
		type: types.SET_EDIT_DEPARTMENT_PROPERTIES,
		edit_department_properties,
	};
}

export function setEditTab(tab_name) {
	return {
		type: types.SET_EDIT_TAB,
		tab_name,
	};
}

export function setSearchedVehicles({ vehicles }) {
	return {
		type: types.SET_SEARCHED_VEHICLES,
		vehicles,
	};
}

export function setCurrentDepartment({ department }) {
	return {
		type: types.SET_CURRENT_DEPARTMENT,
		department,
	};
}

export function setActiveVehicle({ active_vehicle }) {
	return {
		type: types.SET_ACTIVE_VEHICLE,
		active_vehicle,
	};
}

export function setIntervalNumber(id) {
	return {
		type: types.SET_INTERVAL_NUMBER,
		id,
	};
}

export function setIntervalNumberB(id) {
	return {
		type: types.SET_INTERVAL_NUMBER_B,
		id,
	};
}

export function setIntervalVinScanner(id) {
	return {
		type: types.SET_INTERVAL_VIN_SCANNER,
		id,
	};
}

export function setLastUpdated(last_updated) {
	return {
		type: types.SET_LAST_UPDATED,
		last_updated,
	};
}

export function is_updating(updating) {
	return {
		type: types.IS_UPDATING,
		updating,
	};
}

export function setWorkItemPresets({ work_item_presets }) {
	return {
		type: types.SET_WORK_ITEM_PRESETS,
		work_item_presets,
	};
}

export function setAudits({ audits }) {
	return {
		type: types.SET_AUDITS,
		audits,
	};
}

export function setUserFilter(user_filter) {
	return {
		type: types.SET_USER_FILTER,
		user_filter,
	};
}

export function setDealerSettings({ dealer_settings }) {
	return {
		type: types.SET_DEALER_SETTINGS,
		dealer_settings,
	};
}

export function setShouldLogin(status) {
	return {
		type: types.SET_SHOULD_LOGIN,
		status,
	};
}

export function setVinScannerHeight(height, width) {
	return {
		type: types.SET_VIN_SCANNER_HEIGHT,
		height,
		width,
	};
}

export function setVinScannerWidth(width) {
	return {
		type: types.SET_VIN_SCANNER_WIDTH,
		width,
	};
}

export function setLoginRedirectDeepLink(deep_link) {
	return {
		type: types.SET_LOGIN_REDIRECT_DEEP_LINK,
		deep_link,
	};
}

export function setVinScanner(vin_scanner) {
	return {
		type: types.VIN_SCANNER,
		vin_scanner,
	};
}

export function setVinDecodedVehicle(vehicle) {
	return {
		type: types.VIN_DECODED_VEHICLE,
		vehicle,
	};
}

export function resetAllState() {
	return {
		type: types.RESET_ALL_STATE,
	};
}

export function resetNav() {
	return {
		type: types.RESET_NAV,
	};
}

export function resetAdmin() {
	return {
		type: types.RESET_ADMIN,
	};
}

export function resetSite() {
	return {
		type: types.RESET_SITE,
	};
}

export function resetRefresh() {
	return {
		type: types.RESET_REFRESH,
	};
}
export function resetDealerships() {
	return {
		type: types.RESET_DEALERSHIPS,
	};
}
export function resetVinScanner() {
	return {
		type: types.RESET_VIN_SCANNER,
	};
}
export function resetDepartments() {
	return {
		type: types.RESET_DEPARTMENTS,
	};
}
export function resetUsers() {
	return {
		type: types.RESET_USERS,
	};
}
export function resetReports() {
	return {
		type: types.RESET_REPORTS,
	};
}
export function resetFeed() {
	return {
		type: types.RESET_FEED,
	};
}
