import React, {useState} from 'react';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';

import './VehicleDisplayInfoBody.scss'
import {
	lookupPermission,
	lookupIsAdmin,
} from '../../../../actions/lookups';
import VehicleNoteContainer from './VehicleNoteContainer';
import CopyIcon from '../../../../assets/icons/copyIcon'

function VehicleDisplayInfoBody(props){
  const {vehicle} = props
	const [showAdditionalItems, setShowAdditionalItems] = useState(false)
	const [showCopiedSuccess, setShowCopiedSuccess] = useState(false)
  const vehicleProperty = (name) => {

    const value = _.filter(vehicle.vehicle_properties, o => o.key === name);

    if (value.length > 0) {
      if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
        return value[0].value;
      }
    } else {
      return '';
    }
  }
  const certification  = () =>{
    if (vehicleProperty('certified') == 'true') {
      return 'Certified';
    } else if (vehicleProperty('not certified') == 'true') {
      return 'Not Certified';
    } else if (vehicleProperty('wholesale') == 'true') {
      return 'Wholesale';
    }
  }
	const copyToClipboard = (value) => {
		copy(value);
		setShowCopiedSuccess(true)
		setTimeout(() => { setShowCopiedSuccess(false) }, 2000);
	}
  let certStr = certification()
  return(
    <div className="vehicle-display-info-container">
      <div className="vehicle-display-info">
        <div className="vehicle-display-info-details">
         <div className="vehicle-display-info-details-ymm">
            {`${vehicle.year?vehicle.year+' ':''}${vehicle.make?vehicle.make+' ':''}${vehicle.model?vehicle.model+' ':''}${vehicle.stocktype?vehicle.stocktype[0].toUpperCase()+vehicle.stocktype.slice(1):''}`}
         </div>
         {/*  certStr?
           <div>
            <div>{`${certStr}`}</div>
           </div>
           :null
         */}
         <div className="vehicle-display-info-details-vin-stock">
            <div><b>VIN:</b>{` ${vehicle.vin}`}<span className="vehicle-display-info-details-vin-stock-copy" onClick={()=>{copyToClipboard(vehicle.vin)}} ><CopyIcon /></span>{showCopiedSuccess?'Copied!':''}</div>
            <div><b>Stock:</b>{` ${vehicle.stock}`}</div>
						<div><b>Miles:</b>{` ${vehicle.miles?vehicle.miles:'N/A'}`}</div>
						{vehicle.color?
							<div><b>Exterior:</b>{` ${vehicle.color?vehicle.color:'N/A'}`}</div>
							:
							<React.Fragment/>
						}


         </div>
				 <div className="vehicle-display-info-details-additional">
				 	<div onClick={()=>{setShowAdditionalItems(!showAdditionalItems)}}className={`vehicle-display-info-details-additional-toggle${showAdditionalItems?' vehicle-display-info-details-additional-toggle-open':''}`}>
						{`Additional Info ${showAdditionalItems?'-':'+'}`}
					</div>
					{showAdditionalItems?
						<div className="vehicle-display-info-details-additional-items">


						 <div className="vehicle-display-info-details-item">
								 <b>Trim:</b>{` ${vehicle.trim?vehicle.trim:'N/A'}`}
							</div>

						 <div className="vehicle-display-info-details-item">
								 <b>Engine:</b>{` ${vehicle.engine?vehicle.engine:'N/A'}`}
							</div>
							{vehicle.color?
								<React.Fragment/>
								:
								<div className="vehicle-display-info-details-item">
										<b>Exterior:</b>{` ${vehicle.color?vehicle.color:'N/A'}`}
								 </div>
							}

						 <div className="vehicle-display-info-details-item">
								 <b>Interior:</b>{` ${vehicle.interior_color?vehicle.interior_color:'N/A'}`}
							</div>
						 <div className="vehicle-display-info-details-item">
								 <b>Drivetrain:</b>{` ${vehicle.drivetrain?vehicle.drivetrain:'N/A'}`}
							</div>
							<div className="vehicle-display-info-details-item">
								 <b>Inventory Type:</b>{` ${vehicle.inventory_type?vehicle.inventory_type:'N/A'}`}
							</div>
						</div>
						:
						<React.Fragment/>
					}
				 </div>

       </div>
       {
        props.lookupPermission('notes_add')|| props.lookupIsAdmin()?
        <div className="vehicle-display-info-notes">
          <VehicleNoteContainer {...props} vehicle={vehicle} />
        </div>
        :
        null
        }
      </div>
    </div>
  )
}
const mappedActions = {
	lookupPermission,
	lookupIsAdmin,
};
function mapStateToProps(state) {
	return {
		current_department: state.settings_nav.department,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		edit_vehicle: state.settings_nav.edit_vehicle,
		all_tabs: state.settings_site.all_tabs,
		expand_all: state.settings_nav.menus.expand_all,
		all_departments: state.settings_departments.original_departments,
		departments: state.settings_departments.departments,
		current_dealership: state.settings_dealerships.current_dealership,
		vehicle_notes: state.settings_refresh.vehicle_notes,
		current_user: state.current_user,
		mappedActions: state.settings_dealerships.installation,
		softname: state.settings_dealerships.installation.softname || '',
		installation: state.settings_dealerships.installation || '',
		vehicle_columns: state.settings_departments.vehicle_columns,
		dealerships: state.settings_dealerships.set_dealerships,
		interval_number: state.settings_nav.interval_number,
		default_profiles: state.settings_users.default_profiles,
		current_user_profile: state.settings_users.current_user_profile,
	};
}
export default connect(mapStateToProps, mappedActions)(VehicleDisplayInfoBody)
