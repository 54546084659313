import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import Dropdown from '../../../../inputs/Dropdown';
import TabLink from './TabLink';
import CopyIcon from '../../../../assets/icons/copyIcon'
import {
	lookupPermission,
	lookupProfilePermission,
	lookupIsAdmin,
	activeVehicle,
	vsearch,
} from '../../../../actions/lookups';
import {
	setVehicleTab,
} from '../../../../actions/apiSetInfo';
import {
	getCurrentDepartment,
} from '../../../../actions/apiGetInfo';
import {
	numberWithCommas,
} from '../../../../utils/dateFunctions';
import {
	updateVehicleData,
} from '../../../../actions/AllUpdates';
import './VehicleDisplayTabSelection.scss'
class VehicleDisplayTabSelection extends PureComponent {
	constructor(props) {
		super(props);
		this.state = { copied: '' };
		this.onTabClick = this.onTabClick.bind(this);
		this.copyToClipboard = this.copyToClipboard.bind(this);
		this.unDeleteVehicle = this.unDeleteVehicle.bind(this);
	}

	onTabClick(e) {
		if (this.props.vehicle_tab === 'vehicle-body' && this.props.edit_vehicle === this.props.vehicle.id) {
			this.props.setVehicleTab('vehicle-body', 0);
		} else {
			this.props.setVehicleTab('vehicle-body', this.props.vehicle.id);
		}
	}

	department(id) {
		const self = this;
		const department = _.sortBy(_.filter(self.props.departments, o => o.id == id), 'id');
		return department[0] || [];
	}

	unDeleteVehicle() {
		const self = this;
		const data = {
			id: this.props.vehicle.id,
			sold: false,
			deleted: false,
		};

		Promise.all([
			this.props.updateVehicleData(this.props.vehicle.id, data),
		]).then(() => {
			if (self.props.vehicle_filter.type === 'searched') {
				self.props.vsearch(vehicle_filter.value);
			} else {
				self.props.getCurrentDepartment(self.props.vehicle.department_id);
			}
		});
	}

	copyToClipboard(e) {
		this.setState({ copied: 'Copied!' });
		setTimeout(() => { this.setState({ copied: '' }); }, 3000);
		copy(this.props.vehicle.vin);
	}

	dealerSettingValue(name) {
		const self = this;

		const value = _.sortBy(_.filter(self.props.dealer_settings, o => o.key === name), 'id');

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	vehicleProperty(name) {
		const self = this;

		const value = _.filter(self.props.vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== undefined && typeof (value[0].value) !== undefined) {
				return value[0].value;
			}
		} else {
			return '';
		}
	}

	selectDepartments() {
		const results = [];
		const isAdmin = this.props.lookupIsAdmin();
		const remove_department_types = ['recently sold', 'data step', 'all reconditioning', 'parent step'];
		if (this.props.softname.includes('classadsfasdfadfsadic')) {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					const showTab = isAdmin || this.props.lookupProfilePermission(`allowed_move_${option.name}`);
					if (showTab) {
						if (typeof option.name !== 'undefined') {
							var obj = { id: parseInt(option.id), label: option.name, selected };
						} else {
							var obj = { id: parseInt(option.id), label: option.name, selected };
						}
						results.push(obj);
					} else if (selected === true) {
						results.push(obj);
					}
				}
			});
		} else if (this.props.softname.includes('classic')) {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		} else if (this.props.installation.database === 'wp') {
			this.props.dealership_departments.forEach((option) => {
				var stocktype = this.props.vehicle.stocktype;
				var work_flow = [];
				var find_department = [];

				if (stocktype === 'N') {
					work_flow = _.find(this.props.work_flows, { name: 'new' }) || [];
					find_department = _.find(work_flow.work_flow_properties, { department_id: option.id }) || [];
				} else {
					work_flow = _.find(this.props.work_flows, { name: 'used' }) || [];
					find_department = _.find(work_flow.work_flow_properties, { department_id: option.id }) || [];
				}

				if (!remove_department_types.includes(option.department_type) && find_department.length !== 0) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		} else {
			this.props.dealership_departments.forEach((option) => {
				if (!remove_department_types.includes(option.department_type)) {
					let selected = false;
					if (option.id === parseInt(this.props.vehicle.department_id)) {
						selected = true;
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					if (typeof option.name !== 'undefined') {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					} else {
						var obj = { id: parseInt(option.id), label: option.name, selected };
					}
					results.push(obj);
				}
			});
		}

		return results;
	}

	render() {
		const { vehicle } = this.props;
		const self = this;
		const isAdmin = this.props.lookupIsAdmin();

		let certified = '';
		let goldstar = '';
		let greenstar = '';
		let purplestar = '';
		let straightstar = '';
		let uvistar = '';
		const salescost = this.vehicleProperty('salescost');

		if (this.vehicleProperty('certified') == 'true') {
			certified = 'Certified |';
		} else if (this.vehicleProperty('not certified') == 'true') {
			certified = 'Not Certified |';
		} else if (this.vehicleProperty('wholesale') == 'true') {
			certified = 'Wholesale |';
		}

		if (this.vehicleProperty('goldstar') == 'true') {
			goldstar = <div className="d-inline"><i className="fa fa-star color-gold" /></div>;
		} else {
			goldstar = '';
		}

		if (this.vehicleProperty('greenstar') == 'true') {
			greenstar = <div className="d-inline"><i className="fa fa-star color-green" /></div>;
		} else {
			greenstar = '';
		}

		if (this.vehicleProperty('purplestar') == 'true') {
			purplestar = <div className="d-inline"><i className="fa fa-star color-purple" /></div>;
		} else {
			purplestar = '';
		}

		if (this.vehicleProperty('straightstar') == 'true') {
			straightstar = <div className="d-inline"><i className="fa fa-star color-pink" /></div>;
		} else {
			straightstar = '';
		}

		if (this.vehicleProperty('uvistar') == 'true') {
			uvistar = <div className="d-inline"><i className="fa fa-star color-skyblue" /></div>;
		} else {
			uvistar = '';
		}

		var atlanta = self.props.installation.subdomain.includes('automaxatlanta');

		var vehicle_cost = true;
		if (self.props.installation && self.props.softname.toLowerCase().includes('stanley')) {
			vehicle_cost = false;
			if (this.props.lookupPermission('vehicle_cost') || isAdmin) {
				vehicle_cost = true;
			}
		}

		if (self.props.installation && self.props.installation.database == 'astorg') {
			vehicle_cost = false;
			if (this.props.lookupPermission('vehicle_cost') || isAdmin) {
				vehicle_cost = true;
			}
		}

		if (parseInt(salescost) > 0 && !atlanta) {
			certified = `${certified} ${vehicle_cost ? ` $${numberWithCommas(parseInt(salescost))}` : ''}`;
		}

		const color_vehicle_tab = self.dealerSettingValue('color_vehicle_tab');
		let listStyle = {
			background: color_vehicle_tab || 'white',
			backgroundColor: color_vehicle_tab || 'white',
			color: 'rgb(60,60,60)',
		};

		const vehicle_tab_override = self.dealerSettingValue('vehicle_tab_override');
		const vehicle_tab_override_meta = self.dealerSettingValue('vehicle_tab_override_meta');
		const override_value = vehicle[vehicle_tab_override_meta] || this.vehicleProperty(vehicle_tab_override_meta);
		const background_color_override = self.dealerSettingValue(`background_color_override_${override_value}`);
		const text_color_override_ = self.dealerSettingValue(`text_color_override_${override_value}`);

		if (vehicle_tab_override === 'true' && override_value !== '') {
			listStyle = {
				background: background_color_override || color_vehicle_tab || 'white',
				backgroundColor: background_color_override || color_vehicle_tab || 'white',
				color: text_color_override_ || self.dealerSettingValue('color_vehicle_tab_text') || 'rgb(60,60,60)',
			};
		}

		var setdisabled = true;

		if (this.props.lookupPermission('vehicle_move_step') || this.props.lookupIsAdmin()) {
			setdisabled = false;
		}

		var loc = <div
			className="col-md-12 dept-pad"
			key={`${vehicle.id}_${vehicle.department_id}`}
		>
			<Dropdown
				{...this.props}
				titleHelper="Departments"
				title={'Select Department'}
				list={this.selectDepartments()}
				update_type={'select_department'}
				user_id={this.props.user_id}
				department_id={vehicle.department_id}
				value={vehicle.department_id}
				vehicle_id={vehicle.id}
				vehicle={vehicle}
				disabled={setdisabled}
			/>
		</div>;

		var customer_name = <div className="d-inline">{this.vehicleProperty('customer name') !== '' ? ` - ${this.vehicleProperty('customer name')}` : ''}</div>;

		var lot_status = '';
		if (this.vehicleProperty('lot status') !== '') {
			lot_status = <div className="d-inline"> <br /> - {this.vehicleProperty('lot status')}</div>;
		}

		if (vehicle.subdomain !== self.props.current_dealership && self.props.current_dealership !== vehicle.subdomain.substring(0, vehicle.subdomain.length - 1) && self.props.installation.show_all_subdomains !== true) {
			var loc = <button className="btn btn-sm btn-warning">{self.department(vehicle.temp_department).name || `Vehicle at ${vehicle.subdomain}`}</button>;
		}

		if (vehicle.sold === true || vehicle.deleted === true) {
			var loc = <button className="btn btn-sm btn-danger" onClick={this.unDeleteVehicle}>Restore Vehicle to Active</button>;
		}

		return (
			<div className="vehicle-display-tab-selection">
				{this.props.all_tabs.map((tab) => {
					const showTab = isAdmin || this.props.lookupProfilePermission(tab.name);
					return (tab.active && showTab && tab.name!=='tab_checkboxes' && tab.name!=='tab_history') ? <TabLink className="test" onTabClick={self.onTabClick} vehicle={vehicle} vehicle_id={vehicle.id} name={tab.name} icon={tab.icon} colortext="white" tab={tab} key={`tab_${tab.id}_${vehicle.id}`} /> : null;
				})}
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		current_department: state.settings_departments.department,
		departments: state.settings_departments.departments,
		dealership_departments: state.settings_departments.dealership_departments,
		dealer_settings: state.settings_site.dealer_settings,
		all_tabs: state.settings_site.all_tabs,
		edit_vehicle: state.settings_nav.edit_vehicle,
		vehicle_tab: state.settings_nav.menus.vehicle_tab,
		current_dealership: state.settings_dealerships.current_dealership,
		installation: state.settings_dealerships.installation,
		current_user_profile: state.settings_users.current_user_profile,
		softname: state.settings_dealerships.installation.softname || '',
		vehicle_filter: state.settings_nav.vehicle_filter,
		work_flows: state.settings_departments.work_flows,
	};
}
export default connect(mapStateToProps, {
	lookupIsAdmin,
	lookupPermission,
	lookupProfilePermission,
	setVehicleTab,
	updateVehicleData,
	activeVehicle,
	getCurrentDepartment,
	vsearch,
})(VehicleDisplayTabSelection);
