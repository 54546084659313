import axios from 'axios';

let recon_api_prefix_default = "ssoapi"

if (window.ENV && window.ENV.RECON_API_PREFIX_DEFAULT) {
  recon_api_prefix_default = window.ENV.RECON_API_PREFIX_DEFAULT
}

let recon_api_postfix = "reconapi.com/api/"

if (window.ENV && window.ENV.RECON_API_POSTFIX) {
  recon_api_postfix = window.ENV.RECON_API_POSTFIX
}

class HomeInfo {
	static host() {
		let database = '';

		if (typeof axios.defaults.headers.common['X-DEALER'] !== 'undefined') {
			database = `${axios.defaults.headers.common['X-DEALER']}.`;
		} else {
			database = `${recon_api_prefix_default}.`;
		}


		let apiAddress = `https://${database}${recon_api_postfix}`;

		if (process.env.NODE_ENV === 'development2') {
			apiAddress = `https://apirefactor.${recon_api_postfix}`;
		}

		return apiAddress;
	}

	static defaulthost() {
		let database = '';
		database = `${recon_api_prefix_default}.`;


		let apiAddress = `https://${database}${recon_api_postfix}`;

		if (process.env.NODE_ENV === 'development2') {
			apiAddress = `https://apirefactor.${recon_api_postfix}`;
		}

		return apiAddress;
	}

	static ssohost(){
		let database = recon_api_prefix_default
		let apiAddress = `https://${database}.${recon_api_postfix}`
		return apiAddress
	}

	static apiKey() {
		let key = `Cleanapi-Token ${process.env.API_PRO}`;
		if (process.env.NODE_ENV === 'development2') {
			key = `Cleanapi-Token ${process.env.API_DEV}`;
		}

		return key;
	}

	static appraisalKey() {
		if (process.env.NODE_ENV === 'development2') {
			return `Cleanapi-Token ${process.env.API_DEV}, access_token=2:18e7eabc4178277125babc41c0c2c2e1:apicall:apitest1`;
		}
		return `Cleanapi-Token ${process.env.API_PRO}, ${process.env.API_PRO}:apicall:userdefault`;
	}

	static ocrApi() {
		return `${process.env.API_OCR}`;
	}

	static vinApi() {
		return 'https://vpic.nhtsa.dot.gov/api/vehicles/decodevin/';
	}

	static recallToken() {
		return `${process.env.API_RECALL}`;
	}
}
export default HomeInfo;
