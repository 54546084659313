import _ from 'lodash';
import * as AllMutates from './AllMutates';
import * as setInfo from './apiSetInfo';
import * as getInfo from './apiGetInfo';
import * as toggleMenu from './toggleMenu';
import * as dateFunctions from '../utils/dateFunctions';
import Api from '../api/reconApi.js';

export function lookupUserById(id) {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		return _.find(users, { id }) || [];
	};
}
export function lookupDealershipById(id) {
	return (dispatch, getState) => {
		const dealerships = getState().settings_dealerships.set_dealerships;

		return _.find(dealerships, { id }) || [];
	};
}
export function lookupDealerSettingByName(key) {
	return (dispatch, getState) => {
		const { dealer_settings } = getState().settings_site;
		const value = _.find(dealer_settings, { key });
		return value ? value.value : 0;
	};
}
export function lookupFilterById(id) {
	return (dispatch, getState) => {
		const { all_filters } = getState().settings_site;

		return _.find(all_filters, { id }) || [];
	};
}
export function lookupSoldVehicleById(id) {
	return (dispatch, getState) => {
		const { sold_vehicles } = getState().settings_refresh;

		return _.find(sold_vehicles, { id }) || [];
	};
}

export function lookupVehiclePropertyByNameAll(id, name) {
	return (dispatch, getState) => {
		const vehicles = getState().settings_refresh.all_vehicles;
		const vehicle = _.find(vehicles, { id });

		const value = _.filter(vehicle.vehicle_properties, o => o.key === name);

		if (value.length > 0) {
			if (typeof (value[0]) !== 'undefined' && typeof (value[0].value) !== 'undefined') {
				return value[0];
			}
		} else {
			return [];
		}
		return false;
	};
}

export function lookupUserNameById(id) {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;
		const user = _.find(users, { id }) || [];
		return user.user_name || '';
	};
}

export function lookupVendors() {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.position === 'vendor');

		return all_users || [];
	};
}

export function findDealership(searchTerm) {
	return (dispatch, getState) => {
		const dealerships = _.values(getState().settings_dealerships.set_dealerships);
		const results = _.filter(dealerships, _.flow(
			_.identity,
			_.values,
			_.join,
			_.toLower,
			_.partialRight(_.includes, searchTerm),
		));

		dispatch(setInfo.setSearchedDealerships({ dealerships: results }));
	};
}

export function lookupAllowedSubdomainByName(subdomain) {
	return (dispatch, getState) => {
		const { set_dealerships } = getState().settings_dealerships;

		return _.find(set_dealerships, { subdomain });
	};
}

export function vsearch(searchTerm, check_sold = false) {
	return (dispatch, getState) => {
		const { include_sold } = getState().settings_nav;
		const allresults = [];
		let sold = '&q[sold_eq]=false';
		if (include_sold === true) {
			sold = '';
		}

		Api.get(`vehicles?q[stock_vsearch]=${searchTerm}${sold}&embed=vehicle_properties,appraisals,vehicle_notes,attachments,completed_departments`, '').then((resp) => {
			const department = {};
			department.vehicles = resp.data;
			department.used = '';
			department.department_properties = [];
			department.vehicles_count = resp.data.length;
			department.name = `Search Results for "${searchTerm}"`;
			const vehicle_filter = { type: 'searched', value: searchTerm };

			const page_number = getState().settings_nav.vehicle_pagination.page_number;
			const page_size = getState().settings_nav.vehicle_pagination.page_size;
			const sort_descending = getState().settings_nav.menus.sort_descending;
			const sort_by_data = getState().settings_nav.menus.sort_by_data;

			dispatch(setInfo.setSearchedVehicles({ vehicles: [] }));
			dispatch(setInfo.setSearchedVehicles({ vehicles: department.vehicles }));
			dispatch(setInfo.setCurrentDepartment({ department }));

			dispatch(setInfo.setVehicleVehicleTotal(department.vehicles.length));
			dispatch(setInfo.setVehiclePageTotal(Math.ceil(department.vehicles.length / page_size)));
			dispatch(setInfo.setVehicles(dateFunctions.paginateList(department.vehicles, page_size, page_number)));
			dispatch(setInfo.setVehicleFilter(vehicle_filter, department));
		});
	};
}

export function activeVehicle(id) {
	return (dispatch, getState) => {
		const allresults = [];

		Api.get(`vehicles/${id}?embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments,recalls`, '').then((resp) => {
			const department = {};
			dispatch(setInfo.setActiveVehicle({ active_vehicle: [] }));
			dispatch(setInfo.setActiveVehicle({ active_vehicle: resp.data }));
			dispatch(setInfo.setActiveAppraisals({ appraisals: resp.data.appraisals }));
			dispatch(setInfo.setPartOrders({ part_orders: resp.data.part_orders }));
		});
	};
}

export function activeEditDepartment(id) {
	return (dispatch, getState) => {
		const allresults = [];

		Api.get(`departments/${id}?embed=department_properties,department_filters,department_dealerships`, '').then((resp) => {
			dispatch(setInfo.setEditDepartment({ edit_department: resp.data }));
		});
	};
}

export function activeEditDepartmentProperties(id) {
	return (dispatch, getState) => {
		const allresults = [];

		Api.get(`department_properties?q[department_id_eq]=${id}`, '').then((resp) => {
			const department = {};
			dispatch(setInfo.setEditDepartmentProperties({ edit_department_properties: [] }));
			dispatch(setInfo.setEditDepartmentProperties({ edit_department_properties: resp.data }));
		});
	};
}

export function activeEditVehicleColumn(id) {
	return (dispatch, getState) => {
		const allresults = [];

		Api.get(`vehicle_columns/${id}?embed=options,vehicle_column_filters`, '').then((resp) => {
			const department = {};
			dispatch(setInfo.setEditVehicleColumn({ edit_vehicle_column: resp.data }));
		});
	};
}

export function reportVehicleSearch(searchTerm, include_sold = false) {
	return (dispatch, getState) => {
		const allresults = [];
		let sold = '&q[sold_eq]=true';
		if (include_sold === true) {
			sold = '';
		}

		Api.get(`vehicles?q[stock_vsearch]=${searchTerm}${sold}&embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments`, '').then((resp) => {
			const department = {};
			department.vehicles = resp.data;
			department.used = '';
			department.department_properties = [];
			department.vehicles_count = resp.data.length;
			department.name = `Search Results for "${searchTerm}"`;
			const vehicle_filter = { type: 'searched', value: searchTerm };
			dispatch(setInfo.setSearchedVehicles({ vehicles: [] }));
			dispatch(setInfo.setSearchedVehicles({ vehicles: department.vehicles }));
			dispatch(setInfo.setCurrentDepartment({ department }));
			dispatch(setInfo.setVehicleFilter(vehicle_filter, department));
			dispatch(toggleMenu.change_page('content'));
		});
	};
}

export function vsearchMobile(searchTerm, include_sold = false) {
	return (dispatch, getState) => {
		const allresults = [];
		let sold = '&q[sold_eq]=false';
		if (include_sold === true) {
			sold = '';
		}

		Api.get(`vehicles?q[stock_vsearch]=${searchTerm}${sold}&embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments`, '').then((resp) => {
			const department = {};
			department.vehicles = resp.data;
			department.used = '';
			department.department_properties = [];
			department.vehicles_count = resp.data.length;
			department.name = `Search Results for "${searchTerm}"`;
			const vehicle_filter = { type: 'searched', value: searchTerm };
			dispatch(setInfo.setSearchedVehicles({ vehicles: department.vehicles }));
			dispatch(setInfo.setCurrentDepartment({ department }));
			dispatch(setInfo.setVehicleFilter(vehicle_filter, department));
			dispatch(toggleMenu.change_page('vehicles'));
		});
	};
}

export function findVehicleVinScanner(searchTerm) {
	return (dispatch, getState) => {
		const allresults = [];
		const sold = '&q[sold_eq]=true';
		Api.get(`vehicles?q[stock_vsearch]=${searchTerm}${sold}&embed=vehicle_properties,appraisals,vehicle_notes,part_orders,attachments,work_items,vehicle_histories,completed_data_steps,completed_departments`, '').then((resp) => {
			const department = {};
			department.vehicles = resp.data;
			department.used = '';
			department.department_properties = [];
			department.vehicles_count = resp.data.length;
			department.name = `Search Results for "${searchTerm}"`;
			const vehicle_filter = { type: 'searchedVinScanner', value: searchTerm };
			dispatch(setInfo.setSearchedVehicles({ vehicles: department.vehicles }));
			dispatch(setInfo.setCurrentDepartment({ department }));
			dispatch(setInfo.setVehicleFilter(vehicle_filter, department));
			dispatch(toggleMenu.change_page('vehicles'));
		});
	};
}

export function findVehicleByStock(stock) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let department = [];
		department = _.find(departments, _.flow(
		    _.property('vehicles'),
		    _.partialRight(_.some, { id }),
		));
		if (typeof department !== 'undefined') {
			return _.find(department.vehicles, { stock }) || [];
		}
		return [];
	};
}

export function lookupDepartmentById(id) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;

		return _.find(departments, { id }) || [];
	};
}

export function lookupVehicleColumnById(id) {
	return (dispatch, getState) => {
		const { vehicle_columns } = getState().settings_departments;

		return _.find(vehicle_columns, { id }) || [];
	};
}

export function lookupDepartmentByName(name) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		return _.find(departments, { name }) || [];
	};
}

export function lookupSupportTicketById(id) {
	return (dispatch, getState) => {
		const { support_tickets } = getState().settings_support_tickets;

		return _.find(support_tickets, { id }) || [];
	};
}

export function lookupSupportTicketsByUserId(user_id) {
	return (dispatch, getState) => {
		const { support_tickets } = getState().settings_support_tickets;

		return _.find(support_tickets, { user_id }) || [];
	};
}

export function lookupSupportTicketByDealership(id) {
	return (dispatch, getState) => {
		const { support_tickets } = getState().settings_support_tickets;

		return _.find(support_tickets, { id }) || [];
	};
}

export function lookupPermission(name) {
	return (dispatch, getState) => {
		const { current_user_profile } = getState().settings_users;
		if (typeof current_user_profile !== 'undefined') {
			if (current_user_profile[name] === true) {
				return true;
			}
		}
		return false;
	};
}

export function lookupProfilePermission(key) {
	return (dispatch, getState) => {
		const { current_user_profile } = getState().settings_users;

		const permission = _.find(current_user_profile.profile_permissions, { key }) || [];
		if (permission.value == 'true') {
			return true;
		}
		return false;
	};
}

export function lookupIsAdmin() {
	return (dispatch, getState) => {
		const { current_user_profile } = getState().settings_users;
		if (current_user_profile.name === 'admin' || current_user_profile.name === 'super_admin') {
			return true;
		}
		return false;
	};
}

export function lookupVehiclePropertyByName(id, name) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let department = [];
		let vehicle = [];
		department = _.find(departments, _.flow(
		    _.property('vehicles'),
		    _.partialRight(_.some, { id }),
		));
		if (typeof department !== 'undefined') {
			vehicle = _.find(department.vehicles, { id }) || [];
		}
		if (typeof vehicle !== 'undefined') {
			return _.find(vehicle.vehicle_properties, { key: name }) || [];
		}

		return [];
	};
}

export function lookupVehiclePropertyValueByName(id, name) {
	return (dispatch, getState) => {
		const vehicle = getState().settings_refresh.active_vehicle;
		if (typeof vehicle !== 'undefined') {
			return _.find(vehicle.vehicle_properties, { key: name }) ? _.find(vehicle.vehicle_properties, { key: name }).value : '';
		}

		return '';
	};
}

export function lookupChecked(id, name) {
	return (dispatch, getState) => {
		const vehicle = getState().settings_refresh.active_vehicle;

		if (typeof vehicle !== 'undefined') {
			const val = _.find(vehicle.vehicle_properties, { key: name }) || [];
			return val.value === 'true' ? 'checked' : '';
		}

		return [];
	};
}

export function lookupVehicleEstimateById(vehicle_id, id, name) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let department = [];
		let vehicle = [];
		department = _.find(departments, _.flow(
		    _.property('vehicles'),
		    _.partialRight(_.some, { id: vehicle_id }),
		));
		if (typeof department !== 'undefined') {
			vehicle = _.find(department.vehicles, { id: vehicle_id }) || [];
		}
		if (typeof vehicle !== 'undefined') {
			return _.find(vehicle.appraisals, { id }) || [];
		}

		return [];
	};
}

export function lookupVehicleById(id) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		let department = [];
		department = _.find(departments, _.flow(
		    _.property('vehicles'),
		    _.partialRight(_.some, { id }),
		));
		if (typeof department !== 'undefined') {
			return _.find(department.vehicles, { id }) || [];
		}
		return [];
	};
}

export function lookupVehicleByIdAllVehicles(id) {
	return (dispatch, getState) => {
		const { all_vehicles } = getState().settings_refresh;
		return _.find(all_vehicles, { id }) || [];
	};
}

export function lookupRecipients() {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.approve_estimates === true);

		return all_users || [];
	};
}

export function lookupTransporters() {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.position === 'transport');

		return all_users || [];
	};
}

export function lookupProfileUsers(position_name) {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.position === position_name);

		return all_users || [];
	};
}

export function lookupProfileUsersCount(position_name) {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.position === position_name) || [];

		return all_users.length;
	};
}

export function lookupService() {
	return (dispatch, getState) => {
		const users = getState().settings_users.related_users;

		const all_users = _.filter(users, o => o.position === 'service' || o.position === 'service_manager');

		return all_users || [];
	};
}

export function lookupWorkFlowDepartmentsByWorkFlowName(name) {
	return (dispatch, getState) => {
		const { departments } = getState().settings_departments;
		const found = departments.filter(department => (department.work_flow !== null ? department.work_flow.toLowerCase().includes(name.toLowerCase()) : false));

		return found;
	};
}

export function lookupWorkFlowByName(name) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		return _.find(work_flows, { name }) || [];
	};
}

export function lookupWorkFlowIdByName(name) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		var id = _.find(work_flows, { name }) || [];
		return id.id;
	};
}

export function lookupWorkFlowById(id) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		return _.find(work_flows, { id }) || [];
	};
}

export function lookupWorkFlowPropertiesByWorkFlow(id) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		const work_flow = _.find(work_flows, { id }) || [];
		return work_flow.work_flow_properties || [];
	};
}

export function lookupWorkFlowPropertiesByWorkFlowName(name) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		const work_flow = _.find(work_flows, { name }) || [];
		return work_flow.work_flow_properties || [];
	};
}

export function lookupWorkFlowDepartment(name, department_id) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		const work_flow = _.find(work_flows, { name }) || [];
		const department_found = _.find(work_flow.work_flow_properties, { department_id });
		if (department_found) {
			return true;
		}
		return false;
	};
}

export function lookupSortableWorkFlowPropertiesByWorkFlow(id) {
	return (dispatch, getState) => {
		const { work_flows } = getState().settings_departments;
		const work_flow = _.find(work_flows, { id }) || [];
		const work_flow_properties = work_flow.work_flow_properties || [];
		const items = work_flow_properties.map(item => ({
			id: item.department_id,
			text: dispatch(lookupDepartmentNameById(item.department_id)),
			property_id: item.id,
			order: item.order,
		}));
		return _.sortBy(items, 'order');
	};
}

export function lookupDepartmentNameById(id) {
	return (dispatch, getState) => {
		const departments = getState().settings_departments.original_departments;

		const all_departments = _.filter(departments, o => parseInt(o.id) === parseInt(id));

		let return_name = '';
		if (all_departments.length > 0) {
			if (all_departments[0].display_name) {
				return_name = all_departments[0].display_name;
			} else {
				return_name = all_departments[0].name;
			}
		}

		return return_name || '';
	};
}

export function findUser(searchTerm) {
	return (dispatch, getState) => {
		const all_users = _.values(getState().settings_users.related_users);
		const results = _.filter(all_users, _.flow(
			_.identity,
			_.values,
			_.join,
			_.toLower,
			_.partialRight(_.includes, searchTerm),
		));

		let user_filter = {};
		user_filter = { type: 'searched', value: searchTerm };

		dispatch(setInfo.setUsersTab('users'));
		dispatch(setInfo.setSearchedUsers({ searched_users: results }));
		dispatch(setInfo.setUserFilter(user_filter));
	};
}

export function lookupEstimatesByVid(vid) {
	return (dispatch, getState) => {
		const { estimates } = getState().settings_refresh;

		const all_estimates = _.filter(estimates, o => parseInt(o.vehicle_id) === parseInt(vid));

		return all_estimates || [];
	};
}
